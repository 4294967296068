<template>
    <div>
        <v-form v-if="showForm" :lazy-validation="true" ref="form" @submit.prevent="submit" class="createTeamForm">
            <h3 class="mb-5" >{{$t('WELCOME_CREATE_A_TEAM_TITLE')}}</h3>
            <v-row>
                <v-col cols="12">
                    {{$t('WELCOME_CREATE_A_TEAM_MESSAGE')}}
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="teamName" :label="$t('WELCOME_CREATE_A_TEAM_TEAM_NAME')" :rules="nameRules" :counter="maxNameLength" :disabled="isLoading" :dark="isDark" class="createTeamTeamName" id="createTeamTeamName"/>
                </v-col>
                <v-col cols="12">
                    <v-btn @click="onCanceled" :disabled="isLoading" :dark="isDark" text class="createTeamCancel">
                        {{$t('WELCOME_CREATE_A_TEAM_CANCEL')}}
                    </v-btn>
                    <v-btn @click="onConfirmed" :disabled="isLoading" :loading="showForm && isLoading" :dark="!isConfirmLight" :light="isConfirmLight" color="confirm" class="elevation-0 createTeamConfirm">
                        {{$t('WELCOME_CREATE_A_TEAM_CONFIRM')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-btn v-else style='width:100%' text @click="onClicked" :disabled="isLoading" :dark="isDark" id="createTeamButton">
            {{$t('WELCOME_CREATE_A_TEAM')}}
        </v-btn>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Rule from '@/validations/Rule';
import { Prop } from 'vue-property-decorator';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Player, IPlayerApiClient } from '@cyber-range/cyber-range-api-ctf-player-client';
import Route from '@/interfaces/route';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useEntitlementStore } from '@stores/entitlementStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { usePlayerStore } from '@/stores/playerStore';

@Component
export default class NewTeamOption extends Vue 
{   
    @Prop(Boolean) value;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isConfirmLight(): boolean
    {
        return useThemeStore().isConfirmLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get identityId(): string
    {
        return useAuthenticationStore().identityId;
    }
    get identityName(): string
    {
        return useAuthenticationStore().identityName;
    }
    get identityEmail(): string
    {
        return useAuthenticationStore().identityEmail;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    teamName:string = '';
    showForm:boolean = false;
    maxNameLength:number = 100;
    nameRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxNameLength)];

    mounted() 
    {
        this.showForm = this.value;
    }

    async onClicked()
    {
        this.$emit('input', true);
        this.showForm = true;
    }

    async onCanceled()
    {
        this.$emit('input', false);
        this.showForm = false;
    }

    async onConfirmed()
    {
        await this.submit();
    }

    async submit()
    {
        if((<any>this.$refs.form).validate() === false) return;

        let player = new Player({
            competitionId: this.competition.id,
            userId: this.identityId,
            name: this.identityName,
            email: this.identityEmail
        })

        player['teamName'] = this.teamName;

        await usePlayerStore().createPlayer(player);

        this.$router.replace(Route.Challenges);
    }
}
</script>