<template>
    <div>
        <delete-confirmation-dialog v-model="showConfirmationDialog" :checkbox="true" :confirm="$t('TEAMS_DELETE_DIALOG_CONFIRM')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="deleteTeamsDialog">
            <span class="deleteTeamsDialogBodyMessage">{{ $t('TEAMS_DELETE_TEAMS_DIALOG_MESSAGE', {count}) }}</span>
        </delete-confirmation-dialog>
        <job-progress-dialog :jobId="jobId" v-model="showJobProgressDialog" @confirm="onJobDone" :dialogTitle="$t('TEAMS_DELETE_JOB_PROGESS_DIALOG_TITLE')"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@/interfaces/storeGetter';
import { IJobApiClient } from '@cyber-range/cyber-range-api-job-client';
import { Prop, Watch } from 'vue-property-decorator';
import { ITeam } from '@cyber-range/cyber-range-api-user-client';
import { ITeamApiClient } from '@cyber-range/cyber-range-api-ctf-team-client';
import { IPlayer } from '@cyber-range/cyber-range-api-ctf-player-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { usePlayerStore } from '@/stores/playerStore';

@Component({})
export default class DeleteTeamsDialog extends Vue 
{
    get currentPlayer(): IPlayer
    {
        return usePlayerStore().getCurrentPlayer;
    }

    @Prop(Boolean) value: boolean;
    @Prop(Array) teams:ITeam[];

    showConfirmationDialog:boolean = false;
    showJobProgressDialog:boolean = false;
    jobId:string = null;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get bulkTeamApiClient(): ITeamApiClient
    {
        return useApiClientStore().bulkTeamApiClient;
    }
    get jobApiClient(): IJobApiClient
    {
        return useApiClientStore().jobApiClient;
    }
    // END TODO
    
    get includesOwnTeam(): boolean
    {
        if (!this.currentPlayer?.teamId)
        {
            return false;
        }
        else
        {
            return !!this.teams.find(team => team.id === this.currentPlayer?.teamId);
        }
    }

    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get count(): number
    {
        return this.teams?.length || 0;
    }
    
    async confirm()
    {
        await Promise.all(this.teams.map(team => this.bulkTeamApiClient.delete(team.id)));
        this.jobId = await this.jobApiClient.submit();
        this.showConfirmationDialog = false;
        this.showJobProgressDialog = true;
    }

    onJobDone()
    {
        if (this.includesOwnTeam)
        {
            window.location.reload();
        }
        else
        {
            this.$emit('confirm', true);
            this.close();
        }
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.showJobProgressDialog = false;
        this.$emit('input', false);
    }
}
</script>
