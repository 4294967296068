import Config from '@/config';
import { AuthenticationApiClient } from '@cyber-range/cyber-range-api-authentication-client';
import { ApiRequestConfig, IApiClientError } from '@cyber-range/cyber-range-api-client';
import { TrackJS } from 'trackjs';
import { defineStore, storeToRefs } from 'pinia';
import { AgreementApiClient } from '@cyber-range/cyber-range-api-agreement-client';
import { EntitlementApiClient } from '@cyber-range/cyber-range-api-entitlement-client';
import { OrganizationApiClient } from '@cyber-range/cyber-range-api-organization-client';
import { CompetitionApiClient } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IChallengeApiClient, ChallengeApiClient, ChallengeEnvironmentApiClient, IChallengeEnvironmentApiClient } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { IPlayerApiClient, PlayerApiClient } from '@cyber-range/cyber-range-api-ctf-player-client';
import { IUserApiClient, UserApiClient } from '@cyber-range/cyber-range-api-user-client';
import { ContentApiClient } from '@cyber-range/cyber-range-api-content-client';
import { ILibraryApiClient, LibraryApiClient } from '@cyber-range/cyber-range-api-ctf-library-client';
import { ITeamApiClient, TeamApiClient } from '@cyber-range/cyber-range-api-ctf-team-client';
import { SubmissionApiClient } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { FileApiClient } from '@cyber-range/cyber-range-api-file-client';
import { IInvitationApiClient, InvitationApiClient } from '@cyber-range/cyber-range-api-invitation-client';
import { IJobApiClient, JobApiClient, BulkApiClient } from '@cyber-range/cyber-range-api-job-client';
import { SubscriptionApiClient } from '@cyber-range/cyber-range-api-subscription-client';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useErrorStore } from '@stores/errorStore';
import { IFileApiClient } from '@cyber-range/cyber-range-api-file-client';
import { ICatalogFamilySearchApiClient, CatalogFamilySearchApiClient, CatalogFamilyApiClient, CatalogApiClient, ICatalogApiClient } from '@cyber-range/cyber-range-api-catalog-client';
import { ICatalogFamilyApiClient } from '@cyber-range/cyber-range-api-catalog-client/dist/interfaces/iCatalogFamilyApiClient';

async function customApiBackgroundErrorHandler(e:IApiClientError) 
{
    if(e.statusCode === 403 || e.statusCode === 422 || (e.statusCode === 400 && e.message === 'jwt expired'))
    {
        return await useAuthenticationStore().logout();
    }

    TrackJS.track(e);
    console.error(e);
}
export function customApiErrorHandler(e:IApiClientError) 
{
    TrackJS.track(e);
    useErrorStore().setError(e);
}
export function doNothing(e: IApiClientError) 
{
    // Intentionally do nothing
}
export function apiCallingHandler() 
{
    useApiClientStore().loadingBegin()
}
export function apiCalledHandler() 
{
    useApiClientStore().loadingEnd()
}

function createApiClient<T>(T:{new(...args: any[]):T}, baseUrl:string, options?: {errorHandler?:Function|null, callingHandler?:Function|null, calledHandler?:Function|null, fileApiClient?:IFileApiClient} ): T
{
    let { token } = storeToRefs(useAuthenticationStore());

    let config = new ApiRequestConfig(
                            { "Authorization": `Bearer ${token.value}`, 'User-Agent': undefined },
                            undefined,
                            options?.errorHandler === null ? undefined : options?.errorHandler || customApiErrorHandler,
                            options?.callingHandler === null ? undefined : options?.callingHandler || apiCallingHandler,
                            options?.calledHandler === null ? undefined : options?.calledHandler || apiCalledHandler);

    return options?.fileApiClient ? new T(baseUrl, options.fileApiClient, config) : new T(baseUrl, config);
}
function createBackgroundApiClient<T>(T:{new(...args: any[]):T}, baseUrl:string, options?: { fileApiClient?:IFileApiClient }): T
{
    return createApiClient(T, 
                           baseUrl, 
                           { 
                                errorHandler: customApiBackgroundErrorHandler, 
                                callingHandler: null, 
                                calledHandler: null,
                                fileApiClient: options?.fileApiClient
                           })
}
function createBulkApiClient<T>(T:{new(...args: any[]):T}, baseUrl:string, jobClient: IJobApiClient, options?: { fileApiClient: IFileApiClient }): T
{
    return options?.fileApiClient ?  new T(undefined, options.fileApiClient, undefined, new BulkApiClient(baseUrl, undefined, jobClient)) : new T(undefined, undefined, new BulkApiClient(baseUrl, undefined, jobClient));
}

export const useApiClientStore = defineStore('apiClientStore', 
{
    state: () =>
    ({
        loading: 0,
        _authenticationApiClient: undefined,
        _entitlementApiClient: undefined,
        _userApiClient: undefined,
        _invitationApiClient: undefined,
        _organizationApiClient: undefined,
        _agreementApiClient: undefined,
        _backgroundAgreementApiClient: undefined,
        _subscriptionApiClient: undefined,
        _fileApiClient: undefined,
        _competitionApiClient: undefined,
        _backgroundCompetitionApiClient: undefined,
        _jobApiClient: undefined,
        _contentApiClient: undefined,
        _playerApiClient: undefined,
        _backgroundPlayerApiClient: undefined,
        _challengeApiClient: undefined,
        _backgroundChallengeApiClient: undefined,
        _challengeEnvironmentApiClient: undefined,
        _backgroundChallengeEnvironmentApiClient: undefined,
        _libraryApiClient: undefined,
        _backgroundLibraryApiClient: undefined,
        _teamApiClient: undefined,
        _backgroundTeamApiClient: undefined,
        _submissionApiClient: undefined,
        _backgroundSubmissionApiClient: undefined,
        _catalogApiClient: undefined,
        _backgroundCatalogApiClient: undefined,
        _catalogFamilyApiClient: undefined,
        _backgroundCatalogFamilyApiClient: undefined,
        _catalogFamilySearchApiClient: undefined,
        _backgroundCatalogFamilySearchApiClient: undefined,
        _bulkUserApiClient: undefined,
        _bulkInvitationApiClient: undefined,
        _bulkPlayerApiClient: undefined,
        _bulkTeamApiClient: undefined,
        _bulkLibraryApiClient: undefined,
        _bulkChallengeApiClient: undefined,
    }),
    getters: 
    {
        isLoading: (state) => state.loading > 0,
        authenticationApiClient: (state) => state._authenticationApiClient || createApiClient(AuthenticationApiClient, Config.AUTHENTICATION_API_BASE_URL),
        entitlementApiClient: (state) => state._entitlementApiClient || createApiClient(EntitlementApiClient, Config.ENTITLEMENT_API_BASE_URL),
        userApiClient: (state) => state._userApiClient || createApiClient(UserApiClient, Config.USER_API_BASE_URL),
        invitationApiClient: (state) => state._invitationApiClient || createApiClient(InvitationApiClient, Config.INVITATION_API_BASE_URL),
        organizationApiClient: (state) => state._organizationApiClient || createApiClient(OrganizationApiClient, Config.ORGANIZATION_API_BASE_URL),
        subscriptionApiClient: (state) => state._subscriptionApiClient || createApiClient(SubscriptionApiClient, Config.SUBSCRIPTION_API_BASE_URL),
        agreementApiClient: (state) => state._agreementApiClient || createApiClient(AgreementApiClient, Config.AGREEMENT_API_BASE_URL),
        backgroundAgreementApiClient: (state) => state._backgroundAgreementApiClient || createBackgroundApiClient(AgreementApiClient, Config.AGREEMENT_API_BASE_URL),
        jobApiClient: (state) => state._jobApiClient || createApiClient(JobApiClient, Config.JOB_API_BASE_URL),
        fileApiClient: (state) => state._fileApiClient || createApiClient(FileApiClient, Config.FILE_API_BASE_URL),
        competitionApiClient: (state) => state._competitionApiClient || createApiClient(CompetitionApiClient, Config.COMPETITION_API_BASE_URL),
        backgroundCompetitionApiClient: (state) => state._backgroundCompetitionApiClient || createBackgroundApiClient(CompetitionApiClient, Config.COMPETITION_API_BASE_URL),
        contentApiClient: (state) => state._contentApiClient || createApiClient(ContentApiClient, Config.CONTENT_API_BASE_URL),
        playerApiClient: (state): IPlayerApiClient => state._playerApiClient || createApiClient(PlayerApiClient, Config.PLAYER_API_BASE_URL),
        backgroundPlayerApiClient: (state): IPlayerApiClient => state._backgroundPlayerApiClient || createBackgroundApiClient(PlayerApiClient, Config.PLAYER_API_BASE_URL),
        challengeApiClient(): IChallengeApiClient { return this._challengeApiClient || createApiClient(ChallengeApiClient, Config.CHALLENGE_API_BASE_URL, { fileApiClient: this.fileApiClient }) },
        backgroundChallengeApiClient(): IChallengeApiClient { return this._backgroundChallengeApiClient || createBackgroundApiClient(ChallengeApiClient, Config.CHALLENGE_API_BASE_URL, { fileApiClient: this.fileApiClient }) },

        challengeEnvironmentApiClient(): IChallengeEnvironmentApiClient { return this._challengeEnvironmentApiClient || createApiClient(ChallengeEnvironmentApiClient, Config.CHALLENGE_API_BASE_URL) },
        backgroundChallengeEnvironmentApiClient(): IChallengeEnvironmentApiClient { return this._backgroundChallengeEnvironmentApiClient || createBackgroundApiClient(ChallengeEnvironmentApiClient, Config.CHALLENGE_API_BASE_URL) },

        libraryApiClient(): ILibraryApiClient { return this._libraryApiClient || createApiClient(LibraryApiClient, Config.LIBRARY_API_BASE_URL, { fileApiClient: this.fileApiClient }) },
        backgroundLibraryApiClient(): ILibraryApiClient { return this._backgroundLibraryApiClient || createBackgroundApiClient(LibraryApiClient, Config.LIBRARY_API_BASE_URL, { fileApiClient: this.fileApiClient }) },
        teamApiClient: (state) => state._teamApiClient || createApiClient(TeamApiClient, Config.TEAM_API_BASE_URL),
        backgroundTeamApiClient: (state) => state._backgroundTeamApiClient || createBackgroundApiClient(TeamApiClient, Config.TEAM_API_BASE_URL),
        submissionApiClient: (state) => state._submissionApiClient || createApiClient(SubmissionApiClient, Config.SUBMISSION_API_BASE_URL),
        backgroundSubmissionApiClient: (state) => state._backgroundSubmissionApiClient || createBackgroundApiClient(SubmissionApiClient, Config.SUBMISSION_API_BASE_URL),

        catalogApiClient(): ICatalogApiClient { return this._catalogApiClient || createApiClient(CatalogApiClient, Config.CATALOG_API_BASE_URL) },
        backgroundCatalogApiClient(): ICatalogApiClient { return this._backgroundCatalogApiClient || createBackgroundApiClient(CatalogApiClient, Config.CATALOG_API_BASE_URL) },
        catalogFamilyApiClient(): ICatalogFamilyApiClient { return this._catalogFamilyApiClient || createApiClient(CatalogFamilyApiClient, Config.CATALOG_API_BASE_URL) },
        backgroundCatalogFamilyApiClient(): ICatalogFamilyApiClient { return this._backgroundCatalogFamilyApiClient || createBackgroundApiClient(CatalogFamilyApiClient, Config.CATALOG_API_BASE_URL) },
        catalogFamilySearchApiClient(): ICatalogFamilySearchApiClient { return this._catalogFamilySearchApiClient || createApiClient(CatalogFamilySearchApiClient, Config.CATALOG_FAMILY_SEARCH_API_BASE_URL) },
        backgroundCatalogFamilySearchApiClient(): ICatalogFamilySearchApiClient { return this._backgroundCatalogFamilySearchApiClient || createBackgroundApiClient(CatalogFamilySearchApiClient, Config.CATALOG_FAMILY_SEARCH_API_BASE_URL) },

        // Bulk Clients
        bulkUserApiClient(): IUserApiClient { return this._bulkUserApiClient || createBulkApiClient(UserApiClient, Config.USER_API_BASE_URL, this.jobApiClient) },
        bulkInvitationApiClient(): IInvitationApiClient { return this._bulkInvitationApiClient || createBulkApiClient(InvitationApiClient, Config.INVITATION_API_BASE_URL, this.jobApiClient) },
        bulkPlayerApiClient(): IPlayerApiClient { return this._bulkPlayerApiClient || createBulkApiClient(PlayerApiClient, Config.PLAYER_API_BASE_URL, this.jobApiClient) },
        bulkTeamApiClient(): ITeamApiClient { return this._bulkTeamApiClient || createBulkApiClient(TeamApiClient, Config.TEAM_API_BASE_URL, this.jobApiClient) },
        bulkLibraryApiClient(): ILibraryApiClient { return this._bulkLibraryApiClient || createBulkApiClient(LibraryApiClient, Config.LIBRARY_API_BASE_URL, this.jobApiClient, { fileApiClient: this.fileApiClient }) },
        bulkChallengeApiClient(): IChallengeApiClient { return this._bulkChallengeApiClient || createBulkApiClient(ChallengeApiClient, Config.CHALLENGE_API_BASE_URL, this.jobApiClient, { fileApiClient: this.fileApiClient }) },
    },
    actions:
    {
        loadingBegin(): number
        {
            return ++this.loading;
        },
        loadingEnd(): number
        {
            return --this.loading;
        }
    }
});