import { ICompetition, CompetitionFilter, ICustomCompetitionLimits, ICompetitionApiClient } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IPlayerApiClient, Player } from '@cyber-range/cyber-range-api-ctf-player-client';
import NotificationEvent from '@interfaces/NotificationEvent';
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { defineStore } from 'pinia';
import { useAuthenticationStore } from './authenticationStore';
import { useEntitlementStore } from './entitlementStore';
import { usePlayerStore } from './playerStore';

export const useCompetitionStore = defineStore('competitionStore', 
{
    state: () =>
    ({
        currentCompetition: {} as ICompetition
    }),
    actions:
    {
        clearCurrentCompetition(): void
        {
            this.currentCompetition = <ICompetition>{};
        },
        async joinCompetition(): Promise<void>
        {
            await usePlayerStore().createPlayer(new Player({
                competitionId: this.currentCompetition.id,
                userId: useAuthenticationStore().identityId,
                name: useAuthenticationStore().identityName,
                email: useAuthenticationStore().identityEmail,
            }));
        },
        async refreshCurrentCompetition(payload?: {background: boolean}): Promise<ICompetition|undefined> 
        {
            if(this.currentCompetition?.id)
            {
                let client = payload?.background ? useApiClientStore().backgroundCompetitionApiClient : useApiClientStore().competitionApiClient;

                let competition = await client.getOne(this.currentCompetition.id);

                this.currentCompetition = competition;

                return competition;
            }

            return undefined;
        },

        async fetchCompetition(payload: {subdomain: string, background?: boolean}): Promise<ICompetition|undefined> 
        {
            let client = payload.background ? useApiClientStore().backgroundCompetitionApiClient : useApiClientStore().competitionApiClient;
            let page = await client.get(new CompetitionFilter(<CompetitionFilter>{subdomain: payload.subdomain}));

            if(page.items.length > 0)
            {
                if(this.currentCompetition?.subdomain !== payload.subdomain)
                {
                    let competition = await client.getOne(page.items[0].id);

                    //Set store if the competition is the current one is not set or different
                    this.currentCompetition = competition;
                    useNotificationStore().subscribe({
                        event: NotificationEvent.CompetitionUpdated, 
                        callback: ()=>this.refreshCurrentCompetition({background: true})
                    });
                }

                return page.items[0]; 
            }

            return undefined;
        },

        async fetchCurrentCompetitionLimits(): Promise<Partial<ICustomCompetitionLimits>>
        {
            let limits: Partial<ICustomCompetitionLimits> = {};
            const ctfId = this.currentCompetition?.id
            if (ctfId) 
            {
                const client: ICompetitionApiClient = useApiClientStore().competitionApiClient;
                limits = await client.getCompetitionLimits(ctfId);
            }
            return limits;
        }
    }
});
