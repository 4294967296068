<template>
    <div>
        <v-row row wrap class="ma-2 py-3" justify="space-between">
            <h2 class="px-3">{{$t('CHALLENGES_TITLE')}}</h2>
            <div>
                <v-layout>
                    <add-challenge-menu v-if="canCreateChallenge(competition.id, competition.organizationId)" @added='onChallengesAdded' />
                    <v-flex ml-3>
                        <v-tooltip v-if="canImportChallenges()" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="onImportClicked" v-on="on" class="importChallengesButton" small fab outlined :aria-label="$t('CHALLENGE_IMPORT_TOOLTIP')">
                                    <v-icon>upload</v-icon>
                                </v-btn>
                            </template>
                            {{$t('CHALLENGE_IMPORT_TOOLTIP')}}
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </div>
        </v-row>
        <v-data-table show-select v-model="selectedChallengeViews" item-key="challenge.id" :dark="isDark" :headers="headers" :items="items" :loading="isLoading" :loading-text="$t('CHALLENGES_LOADING')" :no-data-text="$t('CHALLENGES_NO_DATA')" class="elevation-0 alphaBackground" id="manageChallengesTable" @toggle-select-all="onToggleSelectAllChallenges" @item-selected="onChallengeSelected" @current-items="onTableItemsChanged">
            <template v-slot:top>
                <table-header deleteButton :exportButton="canExportChallenges()" :selected="selectedChallenges.length" :page="pageSize" :total="items.length" item="challenges" @select-all="onSelectAllChallenges" @clear="onClearSelectedChallenges" @delete="onDeleteSelectedChallenges" @export="onExportSelectedChallenges"/>
            </template>
            <template v-slot:[`header.action`]="{ header }">
                <td><span class="visually-hidden">{{ header.text }}</span></td>
            </template>
            <template v-slot:header.action>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }"> 
                        <v-icon v-on="on" @click="onExportToCsvClicked" :aria-label="$t('CHALLENGE_HEADER_EXPORT_CSV')" :dark="isDark">save_alt</v-icon>
                    </template>
                    <span> {{$t('CHALLENGE_HEADER_EXPORT_CSV')}} </span>
                </v-tooltip>
            </template>
            <template v-if="canEditChallenge || canDeleteChallenge" v-slot:item.action="{ item }">
                <accessible-menu offset-y :label="$t('CHALLENGE_ACTIONS_LABEL', { name: item.name })">
                    <v-list>
                        <v-list-item v-if="canEditChallenge" @click="onEditChallengeClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>edit</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('CHALLENGE_EDIT')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="canDeleteChallenge" @click="onDeleteChallengeClicked(item)">
                            <v-list-item-avatar>
                                <v-icon>delete</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('CHALLENGE_DELETE')}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </accessible-menu>
            </template>
            <template v-slot:header.data-table-select="{ props, on }">
                <v-checkbox :aria-label="$t('CHALLENGE_TOGGLE_SELECT_ALL_LABEL')" class="pa-0 ma-0"
                    hide-details @change="on.input" :input-value="props.value" 
                    :indeterminate="props.indeterminate" />
            </template>
            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                <v-checkbox class="pa-0 ma-0" hide-details
                    :aria-label="$t('CHALLENGE_SELECT_LABEL', {name: item.name})" 
                    @change="select" :input-value="isSelected" />
            </template>
        </v-data-table>
        <add-edit-challenge-dialog v-model="showEditChallengeDialog" :challengeId="selectedChallenge.id" @confirm="refresh" @deleted="refresh"/>
        <delete-challenge-dialog v-model="showDeleteChallengeDialog" :challenge="selectedChallenge" @confirm="refresh" />
        <delete-challenges-dialog v-model="showDeleteChallengesDialog" :challenges="selectedChallenges" @confirm="refresh" />
        <export-challenges-dialog v-model="showExportChallengesDialog" :challenges="selectedChallenges" @confirm="refresh"/>
        <import-challenges-dialog v-model="showImportDialog" @confirm="refresh"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { Challenge, IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import StatisticsEntryView from './StatisticsEntryView';
import StoreAction from '@interfaces/storeAction';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { csvExport } from '@/utils/csvExport';

@Component({ metaInfo: { title: TitleStrings.en.TITLE_MANAGE_CHALLENGES }})
export default class ManageChallenges extends Vue 
{
    @Getter(StoreGetter.IsChallengesFetched) isChallengesFetched:boolean;
    @Getter(StoreGetter.GetChallenges) getChallenges: ()=>IChallenge[];

    @Action(StoreAction.FetchChallenges) fetchChallenges: ()=>Promise<void>;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canCreateChallenge(competitionId: string, organizationId:string): boolean
    {
        return useAuthorizationStore().canCreateChallenge(competitionId, organizationId);
    }
    canExportChallenges(): boolean
    {
        return useAuthorizationStore().canExportChallenges();
    }
    canImportChallenges(): boolean
    {
        return useAuthorizationStore().canImportChallenges();
    }
    // END TODO

    headers = [];
    showDeleteChallengeDialog:boolean = false;
    showDeleteChallengesDialog:boolean = false;
    showEditChallengeDialog:boolean = false;
    selectedChallengeViews:StatisticsEntryView[] = [];
    selectedChallenge:IChallenge = new Challenge();
    visibleChallengeIds:string[] = [];
    showExportChallengesDialog = false;
    showImportDialog = false;

    get selectedChallenges(): IChallenge[]
    {
        return this.selectedChallengeViews.map(challengeView => challengeView.challenge);
    }

    get canDeleteChallenge()
    {
        return useAuthorizationStore().canDeleteChallenge(this.competition.id, this.competition.organizationId);
    }

    get canEditChallenge()
    {
        return useAuthorizationStore().canUpdateChallenge(this.competition.id, this.competition.organizationId);
    }

    get items(): StatisticsEntryView[]
    {
        return this.getChallenges().map(c => new StatisticsEntryView(c));
    }

    get pageSize(): number
    {
        return this.visibleChallengeIds.length;
    }

    async created() 
    {
        this.headers = [
            { text: this.$t('CHALLENGES_HEADER_NAME'), value: 'name' },
            { text: this.$t('CHALLENGES_HEADER_CATEGORY'), value: 'category' },
            { text: this.$t('CHALLENGES_HEADER_POINTS'), value: 'points', align: 'center'  },
            { text: this.$t('CHALLENGES_HEADER_SUCCESSFUL_ATTEMPTS'), value: 'successfulAttempts', align: 'center' },
            { text: this.$t('CHALLENGES_HEADER_FAILED_ATTEMPTS'), value: 'failedAttempts', align: 'center' }
        ]

        if (useAuthorizationStore().canUpdateChallengeProtectedInformation())
        {
            this.headers.splice(1, 0, { text: this.$t('CHALLENGES_HEADER_CREATION_DATE'), value: 'creationDate' });
        }

        if (this.canEditChallenge || this.canDeleteChallenge)
        {
            this.headers.unshift({ text: 'Actions', value: 'action', align: 'center', sortable: false });
        }

        if(!this.isChallengesFetched)
        {
            this.refresh();
        }
    }

    async refresh()
    {
        this.onClearSelectedChallenges();
        await this.fetchChallenges();
    }

    onChallengesAdded()
    {
        this.refresh();
    }

    onEditChallengeClicked(item: StatisticsEntryView)
    {
        this.selectedChallenge = item.challenge;
        this.showEditChallengeDialog = true;
    }

    onDeleteChallengeClicked(item: StatisticsEntryView)
    {
        this.selectedChallenge = item.challenge;
        this.showDeleteChallengeDialog = true;
    }

    onTableItemsChanged(items: StatisticsEntryView[])
    {
        this.visibleChallengeIds = items.map(({ challenge }) => challenge.id);
    }

    onToggleSelectAllChallenges({ value }: { value: boolean, items: StatisticsEntryView[] })
    {
        // Clear all selected challenges if value if false
        if (!value)
        {
            this.onClearSelectedChallenges();
        }
    }

    onChallengeSelected({ value, item }: { value: boolean, item: StatisticsEntryView })
    {
        // If a challenge on the page is deselected when all challenges were selected, change selection to only be the challenges on this page minus the deselected challenge
        if (this.selectedChallengeViews.length === this.items.length && !value)
        {
            this.selectedChallengeViews = this.selectedChallengeViews.filter(({ challenge }) => challenge.id !== item.challenge.id && this.visibleChallengeIds.includes(challenge.id));
        }
    }

    onSelectAllChallenges()
    {
        this.selectedChallengeViews = [...this.items];
    }

    onClearSelectedChallenges()
    {
        this.selectedChallengeViews = [];
    }

    onExportSelectedChallenges()
    {
        this.showExportChallengesDialog = true;
    }

    async onImportClicked()
    {
        this.showImportDialog = true;
    }

    async onExportToCsvClicked()
    {
        const excludeHeaderValues = ['action']
        const headersToInclude = this.headers.filter(header => !excludeHeaderValues.includes(header['value']));

        const headerRow = headersToInclude.map(header => header['text']);
        const dataRows = this.items.map(item => headersToInclude.map(header => item[header['value']]));

        await csvExport(
                headerRow,
                dataRows,
                `ctf_challenges.csv`);
    }

    async onDeleteSelectedChallenges()
    {
        if (this.selectedChallengeViews.length === 1)
        {
            this.selectedChallenge = this.selectedChallenges[0];
            this.showDeleteChallengeDialog = true;
        }
        else if (this.selectedChallengeViews.length > 1)
        {
            this.showDeleteChallengesDialog = true;
        }
        else
        {
            // Do nothing
        }
    }}
</script>

<style scoped>
.theme--dark .v-data-table
{
    
}
</style>
<style>
.v-data-table td
{
    color: var(--v-text-darken1) !important;
}
</style>