enum StoreAction {
    // ChallengeStore
    FetchChallenges = "fetchChallenges",
    // SubmissionStore
    FetchSubmissions = "fetchSubmissions",
    GetTeamChallengeSubmissionStatistics = "getTeamChallengeSubmissionStatistics",
    ExportSubmissions = "exportSubmissions",
    FetchMyTeamsChallengesStatistics = "FetchMyTeamsChallengesAttempts",
    // AlertStore
    FetchAlerts = "fetchAlerts",
}

export default StoreAction;
