<template>
    <v-card :light="isDialogLight"  class="elevation-0 pa-0 tabbedPane">
        <v-row class="pa-0 ml-0 mr-0" align="start">
            <div class="d-flex">
                <div ref="tablist" class="d-flex flex-column pa-4" aria-orientation="vertical" :aria-label="$t('TABBED_PANE_TABLIST_LABEL')" role="tablist" tabindex="0" @keydown="onTablistKeydown" @focus="focusActiveTab">
                    <div class="pa-4" v-for="(tab, index) of tabs" :key="index">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-badge color="error" content="!" bordered overlap :value="isErrorTab(index)">
                                    <v-btn :id="`tab-${index}`" v-on="on" icon :color="index === activeTab ? 'activeTab' : 'unset'" :disabled="isLoading" @click="onTabClicked(index)" :class="`tabButton tabButton_${index} ${isErrorTab(index)?`tabButton_${index}_error`:''}`" role="tab" tabindex="-1" :aria-selected="index === activeTab" :aria-controls="`tabpanel-${index}`" :aria-label="tab.text">
                                        <v-icon>{{tab.icon}}</v-icon>
                                    </v-btn>
                                </v-badge>
                            </template>
                            <span>{{tab.text}}</span>
                        </v-tooltip>
                    </div>
                </div>
                <v-divider vertical class="mt-4"/>
            </div>
            <v-col class="tabContent ml-4 mr-6 mb-4">
                <div v-for="(tab, index) of tabs" :key="index" v-show="activeTab === index" :id="`tabpanel-${index}`" :class="`tabContent_${index}`" role="tabpanel" tabindex="0" :aria-labelledby="`tab-${index}`">
                    <slot :name="index" />
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@/interfaces/storeGetter';
import { Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useErrorStore } from '@stores/errorStore';

@Component
export default class TabbedPane extends Vue 
{ 
    @Prop(Array) tabs:{icon:string,text:string}[];
    @Prop(Array) errorTabIndexes:number[];
    @Ref('tablist') tablistRef: HTMLElement;
    @PropSync('activeTabReset', { type: Boolean }) resetActiveTab: boolean;

    @Watch('resetActiveTab', { immediate: true })
    onActiveTabPropChanged(value: boolean)
    {
        if (value)
        {
            this.activeTab = 0;
            this.resetActiveTab = false;
        }
    }

    // TODO: Change this to composition api
    get isDialogDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isDialogLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    errorMessage = storeToRefs(useErrorStore()).errorMessage;
    // END TODO

    activeTab = 0;

    onTabClicked(index:number): void
    {
        this.activeTab = index;
    }

    get errorDialogIsShowing(): boolean
    {
        return !!this.errorMessage;
    }

    @Watch('activeTab')
    async focusActiveTab()
    {
        if (this.errorDialogIsShowing) return;
        await this.$nextTick();
        (this.tablistRef?.querySelector('[role="tab"][aria-selected="true"]') as HTMLElement)?.focus();
    }

    onTablistKeydown(event: KeyboardEvent)
    {
        if (event.ctrlKey || event.altKey || event.metaKey) return;

        let tab: number;
        switch (event.key) {
            case 'Home':
                tab = 0;
                event.preventDefault();
                break;
            case 'End':
                tab = this.tabs.length - 1;
                event.preventDefault();
                break;
            case 'ArrowDown':
                tab = ( this.activeTab + 1 ) % this.tabs.length;
                break;
            case 'ArrowUp':
                tab = this.activeTab === 0 ? this.tabs.length - 1 : this.activeTab - 1;
                break;
            default:
                return;
        }
        this.activeTab = tab;
    }

    isErrorTab(index:number): boolean
    {
        return this.errorTabIndexes?.indexOf(index) !== -1;
    }
}
</script>
<style scoped>
.drawer
{
    transform: unset !important;
}
.tabbedPane
{
    height: fit-content;
}
</style>