import * as EmailValidator from 'email-validator';

export default class Rule
{
    static require(v)
    {
        let error = 'Error: A value is required.';

        if(typeof v === 'string')
        {
            return v.trim().length > 0 || error;
        }
        else if(typeof v === 'boolean')
        {
            return v === true || v === false || error;
        }
        else if ( typeof v === 'number' )
        {
            return !Number.isNaN(v) || error;
        }
        else if (Array.isArray(v))
        {
            return v.length > 0 || error
        }
        return !!v || error;
    }

    static requiredCheckbox(v: boolean)
    {
        return !!v
    }

    static selectRequire(v)
    {
        return Rule.require(v === 'invalid' ? undefined : v); 
    }

    static maxSizeInMB(v, sizeInMB)
    {
        return (v==undefined || v===null || v?.size <= sizeInMB*1000*1000) || `Error: The file must be less than ${sizeInMB} MB.`;
    }

    static maxLength(v, length)
    {
        return (v==undefined || v===null ||
            (Array.isArray(v)
                ? (v?.length <= length || `Error: The value must have less than ${length} items.`)
                : (v?.toString().length <= length || `Error: The value must be less than ${length} characters.`))
        );
    }

    static minLength(v, length)
    {
        return (v==undefined || v===null || v?.toString().length >= length) || `Error: The value must be more than ${length} characters.`;
    }

    static hyphenatedLowerCaseAlphanumeric(v)
    {
        return (v==undefined || v===null || /^([a-z0-9\-]*)$/.test(v.toString()) || `Error: The value must be lower case alpha-numeric, and may contain hyphens`);
    }

    static doesNotIncludeInvalidSubstrings(v,invalidSubStrings:string[])
    {
        return (v==undefined || v===null || invalidSubStrings.every(value=> !v.toString().toLowerCase().includes(value.toLowerCase())) || `Error: The value may not contain ${invalidSubStrings}`);
    }

    static maxValue(v, value)
    {
        return Number(v) <= value || `Error: The value must not be greater than ${value}.`;
    }

    static minValue(v, value)
    {
        return Number(v) >= value || `Error: The value must not be less than ${value}.`;
    }

    static isNumber(v)
    {
        return !isNaN(v) || `Error: The value must be a number.`;
    }

    static isLink(v:string)
    {
        return /^https?:\/\//.test(v) || 'Error: The value must start with http:// or https://';
    }

    static email(v)
    {
        return EmailValidator.validate(v) || 'Error: The value must be a valid email address.';
    }
    
    static notRequiredEmail(v)
    {
        return v === "" || Rule.email(v);
    }

    static isValidRegexp(v)
    {
        if (!v.startsWith('/'))
        {
            return "Error: Regular Expressions must start with '/'.";
        }

        if (!/^\/.*\/.*$/.test(v))
        {
            return "Error: Regular Expressions must have ending '/'."
        }

        const regExpLiteralEnd = v.lastIndexOf('/');
        const regExpLiteral = v.substring(1, regExpLiteralEnd);
        const regExpFlags = v.substring(regExpLiteralEnd + 1);

        try
        {
            new RegExp(regExpLiteral, regExpFlags);
            return true;
        }
        catch (error)
        {
            return `Error: Invalid regular expression: ${v}: ${error.message}`;
        }
    }
}
