<template>
    <div :class="`scoreChart ${scoreChartClass}`">
        <v-row v-if="isFetchingTheFirstTime" class="scoreChartLoading">
            <v-col cols="12" align="center" class="mt-10 pt-10">
                <loading />
            </v-col>
        </v-row>
        <v-row v-else-if="hasNoData" align="center" class="mt-5 pt-5 mb-5 pb-5">
            <v-col cols="12" align="center" class="mt-5 pt-5 mb-5 pb-5">
                <p class="scoreChartEmpty">{{$t("SCOREBOARD_CHART_EMPTY")}}</p>
            </v-col>
        </v-row>
         <v-row v-else-if="chartData" align="center">
            <v-col cols="12" align="center">
                <GChart type="LineChart" :data="chartData" :options="chartOptions" class="googleChart" />
            </v-col>
         </v-row>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import StoreGetter from '@interfaces/storeGetter';
import ITeamScoreHistory from '../../../interfaces/iTeamScoreHistory';
import { ITeamSubmissionStatistics } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { ITeam } from '@cyber-range/cyber-range-api-ctf-team-client';
import { Prop } from 'vue-property-decorator';
import { useScoreStore } from '@stores/scoreStore';
import { useTeamStore } from '@/stores/teamStore';

@Component
export default class ScoreChart extends Vue 
{   
    @Prop(String) teamId:string;
    @Prop(Number) height:number;
    @Prop(Boolean) isDark:boolean;

    gridlineColor = '';
    textColor = '';
    isReady = false;
    
    isFetchingTheFirstTime = true;
    
    get scoreChartClass(): string
    {
        return this.isDark ? 'scoreChartDark' : 'scoreChartLight';
    }

    get chartOptions()
    {
        const options = {
            hAxis: {
                title: 'Time',
                titleTextStyle: {
                    color: this.textColor
                },
                textStyle: {
                    color: this.textColor
                },
                gridlines: {
                    count: 5,
                    color: this.gridlineColor,
                    units: {
                        years: {format: 'yyyy'},
                        months: {format: 'MMM yyyy'},
                        days: {format: 'MMM d'},
                        hours: {format: 'h:mm a'},
                        minutes: {format: 'h:mm:ss a'},
                        seconds: {format: 'h:mm:ss a'},
                        milliseconds: {format: 'h:mm:ss a'}
                    }
                }
            },
            vAxis: {
                title: 'Score',
                titleTextStyle: {
                    color: this.textColor
                },
                textStyle: {
                    color: this.textColor
                },
                gridlines: {
                    count: 5,
                    color: this.gridlineColor
                }
            },
            legend: {
                position: 'bottom',
                textStyle: {
                    color: this.textColor
                }
            },
            backgroundColor: { fill:'transparent' },
            interpolateNulls: true,
            height: this.height || 400,
            pointSize: 5,
            chartArea: {height: '70%'},
            titleTextStyle: {
                color: this.textColor
            }
        }
        if (this.teamId)
        {
            // do nothing - don't show a title for just one team
        }
        else if (this.teams.length <= 10) // If teams length is <= 10 then "my team" doesn't exist or is included in top 10"
        {
            options['title'] = this.$t('SCOREBOARD_TOP_10_TEAMS_TITLE');
        }
        else
        {
            options['title'] = this.$t('SCOREBOARD_TOP_10_AND_YOUR_TEAM_TITLE');
        }
        return options;
    }

    get teams(): ITeamSubmissionStatistics[]
    {
        const allTeams: ITeam[] = useTeamStore().getTeams;
        let visibleTeamsIds = new Set(allTeams.filter(team => !team.hidden).map(team => team.id));

        const allTeamScores: ITeamSubmissionStatistics[] = useScoreStore().getTeamsScores;
        const visibleTeamScores = allTeamScores.filter(teamScore => visibleTeamsIds.has(teamScore.teamId));

        if (this.teamId)
        {
            const team: any = useTeamStore().getTeam(this.teamId);
            return team ? [{...team, teamName: team.name, teamId: team.id}] : [];
        }
        else
        {
            // Top 10 teams
            const teams = visibleTeamScores.slice(0,10);

            // Add my team to the list of teams if it's not already included
            const myTeamId: string = useTeamStore().getMyTeam;
            const myTeamIndex = visibleTeamScores.findIndex(team => team.teamId === myTeamId);

            if (myTeamIndex >= 10)
            {
                teams.push(visibleTeamScores[myTeamIndex]);
            }

            return teams;
        }
    }

    get chartData()
    {
        if (!this.isReady)
        {
            return undefined;
        }

        let addedData = false;

        const data = new google.visualization.DataTable();
        data.addColumn('datetime', 'Time');

        for (let i = 0; i < this.teams.length; i++)
        {
            const team = this.teams[i];
            data.addColumn('number', team.teamName);

            const rows = useScoreStore().getTeamScoreHistory(team.teamId).map(({timestamp, score}) => {
                const row = new Array(i + 1).fill(null);
                row[0] = new Date(Date.parse(timestamp));
                row.push(score);
                return row;
            });
            if (rows.length > 0)
            {
                addedData = true;
            }
            data.addRows(rows);
        }

        return addedData ? data : undefined;
    }

    get hasNoData()
    {
        return this.chartData === undefined;
    }

    async created()
    {
        google.charts.load('current', {'packages':['corechart']});
        await new Promise(resolve => google.charts.setOnLoadCallback(resolve));
        this.isReady = true;
        this.isFetchingTheFirstTime = false;
    }
}
</script>