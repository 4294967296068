import { defineStore } from 'pinia';
import IStoreState from '@interfaces/iStoreState';
import { ITeamApiClient, ITeam, ITeamFilter, TeamFilter  } from '@cyber-range/cyber-range-api-ctf-team-client';
import { IPageResponse } from '@cyber-range/cyber-range-api';
import NotificationEvent from '@/interfaces/NotificationEvent';
import Config from '@/config';
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { usePlayerStore } from './playerStore';

export const useTeamStore = defineStore('teamStore',
{
    state: () =>
    ({
        teams: [],
        teamsById: {},
        myTeamId: '',
        isTeamsFetched: false,
        isTeamsFetching: false
    }),
    getters: 
    {
        getTeams(state:IStoreState): ITeam[]
        {
            return state.teams || [];
        },
        getTeam(state:IStoreState): (teamId:string) => ITeam
        {
            return (teamId: string) => state.teamsById[teamId];
        },
        getMyTeam(state:IStoreState): string
        {
            return state.myTeamId || undefined;
        },
    },
    actions: 
    {   
        setTeams(teams:ITeam[]): void
        {
            let teamsTable = {};

            for(let team of teams)
            {
                teamsTable[team.id] = team;
            }

            this.teamsById = teamsTable;
            this.teams = teams || [];

            this.isTeamsFetched = true;
        },
        setMyTeam(teamId:string): void
        {
            this.myTeamId = teamId;
        },
        setIsTeamsFetching(isFetching:boolean): void
        {
            this.isTeamsFetching = isFetching;
        },
        async fetchTeams(options?:{background:boolean}): Promise<void> 
        {
            try
            {
                this.setIsTeamsFetching(true);

                let client:ITeamApiClient = options?.background ? useApiClientStore().backgroundTeamApiClient : useApiClientStore().teamApiClient;
                let page:IPageResponse<ITeam>;
                let teams:ITeam[] = [];
                let filter:ITeamFilter = new TeamFilter({competitionId: useCompetitionStore().currentCompetition.id, limit: Config.DEFAULT_FETCH_SIZE});

                do
                {
                    page = await client.get(filter);
                    filter.token = page.nextPageToken;
                    teams = teams.concat(page.items);
                }
                while(filter.token)

                this.setTeams(teams);
            }
            finally
            {
                this.setIsTeamsFetching(false);
            }

            if(!useNotificationStore().isSubscribed(NotificationEvent.TeamUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.TeamUpdated, 
                    callback: ()=>this.fetchTeams({background: true})
                }); 
            };
        },
        async fetchMyTeam(options?:{background:boolean}): Promise<string>
        {
            if(!useAuthenticationStore().isLogin) return undefined;

            const playerStore = usePlayerStore();
            let player = playerStore.getCurrentPlayer || await playerStore.fetchCurrentPlayer();

            if(player?.teamId)
            {
                this.setMyTeam(player.teamId)
            }

            if(!useNotificationStore().isSubscribed(NotificationEvent.TeamMembershipUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.TeamMembershipUpdated, 
                    callback: ()=>this.fetchMyTeam({background: true})
                }); 
            };

            return player?.teamId;
        }
    }
});
