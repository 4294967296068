import stringify from 'csv-stringify';

export async function csvExport(headers: string[], 
                                data: object[],
                                fileName: string): Promise<void> {
    var csvContentType = "data:text/csv;charset=utf-8,";

    const csvContent = [
        headers, 
        ...data
    ];
    return new Promise((resolve, reject) =>{
        stringify(csvContent, 
            {cast: {boolean: (v) => v ? 'true' : 'false'}}, 
            (error, output) => {
                if(error) return reject(error);

                const usersURI = encodeURI(csvContentType) + encodeURIComponent(output);
                const link = document.createElement('a');
                link.setAttribute('href', usersURI);
                link.setAttribute('download', `${fileName}`);
                link.click();

                resolve();
            }
        );
    });
}
