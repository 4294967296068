import { FamilyRecipeParameter, ICatalogFamily, ICatalogFamilyCatalogEntryView, IFamilyRecipeParameter, IRecipeParameterOption } from "@cyber-range/cyber-range-api-catalog-client";
import { computed, getCurrentInstance, ref, Ref } from "vue";
import { useSubscriptionStore } from '../../../stores/subscriptionStore';

export function useCatalogSelection(originalRecipeParameters: Ref<IFamilyRecipeParameter[]|undefined>, catalogEntries: Ref<ICatalogFamilyCatalogEntryView[]|undefined>)
{
    const vm = getCurrentInstance();

    const subscriptionStore = useSubscriptionStore();
    function isValidCatalogEntryChoice(id: string): boolean
    {
        const catalogEntry = catalogEntries.value.find((entry) => entry.id === id);
        return catalogEntry
            && (!catalogEntry.dependsOnProducts || catalogEntry.dependsOnProducts.every(product => subscriptionStore.hasSubscribedProduct(product)))
            && catalogEntry.metadata?.tags?.['environmentTypes']?.includes('challenge_environment')
    }

    const allowedRecipeParameters = computed(() =>
    {
        if (!originalRecipeParameters.value) return [];

        const parameters: IFamilyRecipeParameter[] = [];
        for (let i = 0; i < originalRecipeParameters.value.length; i++)
        {
            const parameter = new FamilyRecipeParameter({ ...originalRecipeParameters.value[i] });
            if (parameter.id !== 'catalogid')
            {
                parameters.push(parameter);
                continue;
            }
            
            parameter.options = parameter.options?.filter(option => isValidCatalogEntryChoice(option.value));
            if (parameter.default && !isValidCatalogEntryChoice(parameter.default))
            {
                delete parameter.default;
            }
            if (parameter.options?.length || parameter.default)
            {
                parameters.push(parameter);
            }
        }
        return parameters;
    });

    const selectedParameters = ref<Record<IFamilyRecipeParameter['id'], IRecipeParameterOption['value']>>({});

    // Checks that a recipe parameter's when requirement is met
    const isValidWhen = ({ when }: IFamilyRecipeParameter) => !when || Object.entries(when).every(([key,value]) => selectedParameters.value[key] === value)

    const recipeParameters = computed(() =>
    {
        const parameters: IFamilyRecipeParameter[] = [];
        for (let parameter of allowedRecipeParameters.value)
        {
            parameter = new FamilyRecipeParameter(parameter);
            if (!isValidWhen(parameter)) continue;

            // Append 'suggested' to the default parameter option
            parameter.options = parameter.options?.map(option =>
            {
                if (option.value === parameter.default)
                {
                    option = {
                        name: option.name + vm.proxy.$t('ARTIFACTS_ADD_EDIT_DIALOG_ENVIRONMENT_CATALOG_FAMILY_SUGGESTED_PARAMETER_SUFFIX'),
                        value: option.value
                    }
                }
                return option;
            })
            parameters.push(parameter);
        }
        return parameters;
    });

    return { selectedParameters, recipeParameters };
}