export default {
    en: {
        ARTIFACTS_ADD: 'Add an Artifact',
        ARTIFACTS_ADD_DIALOG_TITLE: 'Add an Artifact',
        ARTIFACTS_EDIT_DIALOG_TITLE: 'Edit an Artifact',
        ARTIFACTS_ADD_EDIT_DIALOG_NAME: 'Name',
        ARTIFACTS_ADD_EDIT_DIALOG_LINK_VALUE: 'URL',
        ARTIFACTS_ADD_EDIT_DIALOG_ENVIRONMENT_VALUE: 'Environment',
        ARTIFACTS_ADD_EDIT_DIALOG_ENVIRONMENT_CATALOG_FAMILY_SUGGESTED_PARAMETER_SUFFIX: ' (suggested)',
        ARTIFACTS_ADD_EDIT_DIALOG_TYPE: 'Type',
        ARTIFACTS_ADD_EDIT_DIALOG_DELETE: 'Delete',
        ARTIFACTS_TYPE_LINK: 'Link',
        ARTIFACTS_TYPE_FILE: 'File',
        ARTIFACTS_EDIT_DIALOG_FILE_EDIT: 'New Artifact File',
        ARTIFACTS_EDIT_DIALOG_FILE_ADD: 'File',
        ARTIFACTS_FILE_DOWNLOAD: 'download challenge artifact',
        ARTIFACTS_LINK_TO_CHALLENGE_ARTIFACT: 'link to challenge artifact',
        ARTIFACTS_CHALLENGE_ENVIRONMENT_OPEN: 'launch challenge environment',
        ARTIFACTS_ARTIFACT: 'challenge artifact',
        ARTIFACTS_ENVIRONMENT_ARTIFACT_DISABLED_FOR_SOLVED_CHALLENGE: 'You have already solved this challenge.',
        ARTIFACTS_ENVIRONMENT_ARTIFACT_DISABLED_NOT_A_PLAYER: 'Only CTF players and CTF admins can launch an environment',
    }
};
