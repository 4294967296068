<template>
    <confirmation-dialog v-model="showDialog" :title="dialogTitle" :dense="true" @confirm="confirm" @cancel="cancel" :confirm="confirmButtonText" :loading="isLoadingOrUploading" class="addEditLibraryEntryDialog">
        <loading v-if="dataFetching" class="mt-5 ml-5 mb-5 loading" color="dialogText" />
        <v-form v-else ref="form" :lazy-validation="true">
            <tabbed-pane :tabs="tabs" :activeTabReset.sync="shouldResetActiveTab" :errorTabIndexes="errorTabIndexes">
                <template #0>
                    <v-text-field v-model="entry.name" :label="$t('LIBRARY_ADD_EDIT_DIALOG_NAME')" :rules="nameRules" :counter="maxNameLength" :disabled="isLoadingOrUploading" @update:error="(e)=>onError(e, 0)" class="libraryEntryAddEditDialogName" id="libraryEntryAddEditDialogName" />
                    <v-textarea v-model="entry.description" :label="$t('LIBRARY_ADD_EDIT_DIALOG_DESCRIPTION')" rows="3" :rules="descriptionRules" :counter="maxDescriptionLength" :disabled="isLoadingOrUploading" @update:error="(e)=>onError(e, 0)" class="libraryEntryAddEditDialogDescription" id="libraryEntryAddEditDialogDescription" />
                    <v-text-field v-model="entry.points" :label="$t('LIBRARY_ADD_EDIT_DIALOG_POINTS')" :rules="pointsRules" :disabled="isLoadingOrUploading" @update:error="(e)=>onError(e, 0)" class="libraryEntryAddEditDialogPoints" id="libraryEntryAddEditDialogPoints" />
                    <flags :flags.sync="entry.flags" :choice.sync="entry.choice" class="libraryEntryAddEditDialogFlags" :rules="flagRules" @update:error="(e)=>onError(e, 0)" />
                </template>
                <template #1>
                    <v-subheader class="pl-0 mt-3">{{$t('LIBRARY_ADD_EDIT_DIALOG_ARTIFACTS')}}</v-subheader>
                    <artifacts :artifacts="entry.artifacts" :editable="true" class="libraryEntryAddEditDialogArtifacts" :disabled="isLoadingOrUploading"/>
                    <v-subheader class="pl-0 mt-6">{{$t('LIBRARY_ADD_EDIT_DIALOG_HINTS')}}</v-subheader>
                    <hints :challenge="entry" :editable="true" class="libraryEntryAddEditDialogHints" :disabled="isLoadingOrUploading"/>
                </template>
                <template #2>
                    <v-subheader class="pl-0 mt-3">{{$t('LIBRARY_ADD_EDIT_DIALOG_SETTINGS')}}</v-subheader>
                    <v-checkbox v-model="entry.enabled" :label='$t("LIBRARY_ADD_EDIT_DIALOG_ENABLED")' :dark="isDark" color="unset" :light="isLight" :disabled="isLoadingOrUploading" class="libraryEntryAddEditDialogEnabled" id="libraryEntryAddEditDialogEnabled" />
                    <v-checkbox v-model="global" v-if="showGlobalVisibilityCheckBox" :label='$t("LIBRARY_ADD_EDIT_DIALOG_GLOBAL")' :dark="isDark" color="unset" :light="isLight" :disabled="isLoadingOrUploading" class="libraryEntryAddEditDialogGlobal pt-0 mt-0" id="libraryEntryAddEditDialogGlobal" />
                    <v-checkbox v-model="organizational" v-if="showOrganizationalVisibilityCheckBox" :label='$t("LIBRARY_ADD_EDIT_DIALOG_ORGANIZATIONAL")' :dark="isDark" color="unset" :light="isLight" :disabled="isLoadingOrUploading" class="libraryEntryAddEditDialogOrganizational pt-0 mt-0" id="libraryEntryAddEditDialogOrganizational" />
                    <sorted-select v-model="organizationId" v-if="showOrganizationalVisibilityOrganizationsDropdown" :items="organizations" item-text="name" item-value="id" :label="$t('LIBRARY_ADD_EDIT_DIALOG_ORGANIZATION')" :disabled="isLoadingOrUploading" :hide-no-data="true" :return-object="false" :dark="isDark" class="libraryEntryAddEditDialogOrganization" id="libraryEntryAddEditDialogOrganization" />
                    <sorted-combobox v-model="entry.category" :items="allKnownCategories" :rules="categoryRules" item-text="name" item-value="name" @update:search-input="onCategoryChanged" @keydown.enter.stop :label="$t('LIBRARY_ADD_EDIT_DIALOG_CATEGORY')" :disabled="isLoadingOrUploading" :hide-no-data="true" :return-object="false" :dark="isDark" class="libraryEntryAddEditDialogCategory" />
                    <library-entry-settings :settings="entry.settings" :choice="!!entry.choice" @error="e => onError(e, 2)" class="libraryEntryAddEditDialogSettings mt-0 pt-0" />
                </template>
                <template #3>
                    <v-subheader class="pl-0 mt-3">{{$t('LIBRARY_ADD_EDIT_DIALOG_TAGS')}}</v-subheader>
                    <tags :tags="entry.tags" class="libraryEntryAddEditDialogTags" @error="e => onError(e, 3)"/>
                </template>
                <template #4>
                    <v-textarea v-model="entry.notes" :label="$t('LIBRARY_ADD_EDIT_DIALOG_NOTES')" :aria-label="$t('LIBRARY_ADD_EDIT_DIALOG_NOTES')" rows="5" :rules="notesRules" :counter="maxNotesLength" :disabled="isLoadingOrUploading" class="libraryEntryAddEditDialogNotes"/>
                    <v-textarea v-model="entry.solution" :label="$t('LIBRARY_ADD_EDIT_DIALOG_SOLUTION')"
                    :aria-label="$t('LIBRARY_ADD_EDIT_DIALOG_SOLUTION')" rows="5" :rules="solutionRules" :counter="maxSolutionLength" :disabled="isLoadingOrUploading" class="libraryEntryAddEditDialogSolution"/> 
                </template>
                <template #5>
                    <v-subheader class="pl-0 mt-3">{{$t('LIBRARY_ADD_EDIT_DIALOG_PROTECTED_INFORMATION_NOTICE')}}</v-subheader>
                    <sorted-combobox :items="authors.map(a => a.name)" @change="onAuthorNameChanged" v-model="entry.protectedInformation.authorName" :aria-label="$t('LIBRARY_ADD_EDIT_DIALOG_AUTHOR_NAME')" :label="$t('LIBRARY_ADD_EDIT_DIALOG_AUTHOR_NAME')" :rules="authorNameRules" :counter="authorNameLength" :disabled="isLoadingOrUploading" class="libraryEntryAddEditDialogAuthorName mt-4"/>
                    <sorted-combobox :items="authors.map(a => a.email)" @change="onAuthorEmailChanged" v-model="entry.protectedInformation.authorEmail" :aria-label="$t('LIBRARY_ADD_EDIT_DIALOG_AUTHOR_EMAIL')" :label="$t('LIBRARY_ADD_EDIT_DIALOG_AUTHOR_EMAIL')" :rules="authorEmailRules" :counter="authorEmailLength" :disabled="isLoadingOrUploading" class="libraryEntryAddEditDialogAuthorEmail mt-4 mb-4"/>
                    <v-text-field ref="picker-activator" @click="openDateDialog" @keyup.space="openDateDialog" @click:clear="entry.protectedInformation.creationDate = ''" clearable readonly :value="entry.protectedInformation.creationDate" :label="$t('LIBRARY_ADD_EDIT_DIALOG_CREATION_DATE')" :aria-label="$t('LIBRARY_ADD_EDIT_DIALOG_CREATION_DATE')" aria-haspopup="true" :dark="isDark" :disabled="isLoading" class="libraryEntryAddEditDialogCreationDate"/>
                    <v-dialog v-model="showDateDialog" max-width="290">
                        <v-date-picker ref="picker" tabindex="0" v-model="entry.protectedInformation.creationDate" @change="closeDateDialog"/>
                    </v-dialog>
                </template>
            </tabbed-pane>
        </v-form>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@/interfaces/storeGetter';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { ILibraryApiClient, LibraryEntry, LibraryEntrySettings, ILibraryEntry, IArtifact, Hint, ProtectedInformation } from '@cyber-range/cyber-range-api-ctf-library-client';
import { decode, encode} from 'html-entities';
import { File } from '@cyber-range/cyber-range-api-file-client';
import Config from '@/config';
import { ApiRequestConfig } from '@cyber-range/cyber-range-api-client';
import { customApiErrorHandler, apiCallingHandler, apiCalledHandler } from '@stores/apiClientStore';
import { IOrganization, IOrganizationApiClient, OrganizationFilter, OrganizationSortBy } from '@cyber-range/cyber-range-api-organization-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useLibraryEntryStore } from '@stores/libraryEntryStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component
export default class AddEditLibraryEntryDialog extends Vue 
{ 
    @Prop(Boolean) value:boolean;
    @Prop(String) libraryEntryId:string;
    @Prop({default: () => []}) authors: {name: string, email: string}[];

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get libraryApiClient(): ILibraryApiClient
    {
        return useApiClientStore().libraryApiClient;
    }
    get organizationApiClient(): IOrganizationApiClient
    {
        return useApiClientStore().organizationApiClient;
    }
    get myUserId(): string
    {
        return useAuthenticationStore().identityId;
    }
    get myName(): string
    {
        return useAuthenticationStore().identityName;
    }
    get myEmail(): string
    {
        return useAuthenticationStore().identityEmail;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    maxCategoryLength = 100;
    get categoryRules()
    {
        return [(v)=>Rule.maxLength(v, this.maxCategoryLength)]
    }
    
    isUploading: boolean = false;
    dataFetching:boolean = false;
    showDialog:boolean = false;
    showDateDialog = false;
    entry:ILibraryEntry = new LibraryEntry({
        flags: [], tags: [], hints: [], artifacts: [],
        protectedInformation: new ProtectedInformation(),
        settings: new LibraryEntrySettings()
    });
    errorTabIndexes:number[] = [];
    originalFlags:string = '';
    percentCompleted:number = 0;
    global:boolean = false;
    organizational:boolean = false;
    organizations:IOrganization[] = [];
    organizationId:string = '';
    existingUserId:string = '';
    shouldResetActiveTab = false;

    get allKnownCategories(): string[]
    {
        const challengeCategories: string[] = this.$store.getters[StoreGetter.GetChallengeCategories];
        const libraryCategoreis: string[] = useLibraryEntryStore().libraryCategories;
        return [...new Set([...challengeCategories, ...libraryCategoreis])];
    }

    get authorNames() {
        let names = {};
        for (const author of this.authors) {
            names[author.name] = author.email;
        }
        return names; 
    };

    get authorEmails() {
        let emails = {};
        for (const author of this.authors) {
            emails[author.email] = author.name;
        }
        return emails;
    }

    get showGlobalVisibilityCheckBox():boolean
    {
        return this.canCreateGlobalEntry
    }

    get showOrganizationalVisibilityCheckBox():boolean
    {
        return (this.isEditing && this.canCreateGlobalEntry) || (this.isAdding && this.canCreateOrganizationalEntry);
    }

    get showOrganizationalVisibilityOrganizationsDropdown():boolean
    {
        return  (this.organizations.length > 1 && this.organizational) && ((this.isEditing && this.canCreateGlobalEntry) || (this.isAdding  && this.canCreateOrganizationalEntry));
    }

    maxNameLength = 256;
    maxDescriptionLength = 4096;
    maxPoints = 100000;
    minPoints = 1;
    maxNotesLength = 10000;
    maxSolutionLength = 4096;
    authorNameLength = 256;
    authorEmailLength = 320;

    nameRules = [Rule.require, v => Rule.maxLength(v, this.maxNameLength)];
    descriptionRules = [Rule.require, v => Rule.maxLength(v, this.maxDescriptionLength)];
    pointsRules = [Rule.require, v => Rule.maxValue(v, this.maxPoints), v => Rule.minValue(v, this.minPoints)];
    flagRules = [Rule.require];
    notesRules = [v => Rule.maxLength(v, this.maxNotesLength)];
    solutionRules = [v => Rule.maxLength(v, this.maxSolutionLength)];
    authorNameRules = [v => Rule.maxLength(v, this.authorNameLength)];
    authorEmailRules = [v => Rule.maxLength(v, this.authorEmailLength)];

    get isLoadingOrUploading()
    {
        return this.isLoading || this.isUploading;
    }

    get canCreateGlobalEntry(): boolean
    {
        return useAuthorizationStore().canCreateLibraryEntry(undefined, undefined);
    }

    get canCreateOrganizationalEntry(): boolean
    {
        return useAuthorizationStore().canCreateLibraryEntry(undefined, this.competition.organizationId);
    }

    get tabs()
    {
        const tabs = [
            {icon: 'flag', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_CHALLENGE_INFORMATION')},
            {icon: 'attachment', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_ARTIFACTS_AND_HINTS')},
            {icon: 'settings', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_ADVANCED_SETTINGS')},
            {icon: 'local_offer', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_TAGS')},
            {icon: 'feed', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_NOTES_AND_SOLUTIONS')},
        ];
        if (useAuthorizationStore().canUpdateChallengeProtectedInformation()) {
            tabs.push({icon: 'privacy_tip', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_PROTECTED_INFORMATION')})
        }
        return tabs;
    }
    
    get isAdding(): boolean
    {
        return !this.libraryEntryId;
    }

    get isEditing(): boolean
    {
        return !!this.libraryEntryId;
    }

    get dialogTitle()
    {
        return this.isEditing ? this.$t('LIBRARY_ADD_EDIT_DIALOG_EDIT_TITLE') : this.$t('LIBRARY_ADD_EDIT_DIALOG_ADD_TITLE');
    }

    get confirmButtonText()
    {
        return this.percentCompleted ? this.$t('LIBRARY_ADD_EDIT_DIALOG_UPLOAD_PROGRESS', {percent: this.percentCompleted}) : this.$t('CONFIRM');
    }

    openDateDialog() {
        this.showDateDialog = true;
    }

    closeDateDialog() {
        this.showDateDialog = false;
    }

    onError(error:boolean, index:number): void
    {
        if(error)
        {
            this.errorTabIndexes = [...new Set([...this.errorTabIndexes, index])];
        }
        else
        {
            this.errorTabIndexes = this.errorTabIndexes.filter(i => i !== index);
        }
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        this.showDialog = value;
        if(this.showDialog)
        {
            this.shouldResetActiveTab = true;
            await this.load();
        }
    }

    @Watch('global')
    onGlobalChanged(value)
    {
        if(value) this.organizational = false;
    }
    
    @Watch('organizational')
    onOrganizationalChanged(value)
    {
        if(value) this.global = false;
    }

    onCategoryChanged(value)
    {
        this.entry.category = value;
    }

    onAuthorNameChanged(authorName: string) {
        this.entry.protectedInformation.authorEmail = 
            this.authorNames[authorName] || 
            this.entry.protectedInformation.authorEmail;
    }

    onAuthorEmailChanged(authorEmail: string) {
        this.entry.protectedInformation.authorName = 
            this.authorEmails[authorEmail] || 
            this.entry.protectedInformation.authorName;
    }

    async mounted() 
    {
        this.showDialog = this.value;
        if(this.showDialog) await this.load();
    }

    async load() 
    {
        this.entry = new LibraryEntry({
            flags: [], tags: [], hints: [], artifacts: [],
            protectedInformation: new ProtectedInformation(),
            settings: new LibraryEntrySettings()
        }); 

        let listOrganizationsPromise = this.organizationApiClient.getAll(new OrganizationFilter({sortBy:OrganizationSortBy.Name}));
        useLibraryEntryStore().fetchLibraryEntries();

        await this.$nextTick(); 
        this.errorTabIndexes = [];
        await (<any>this.$refs.form)?.reset();
        
        this.entry.protectedInformation = new ProtectedInformation(
            {
                authorName : this.myName,
                authorEmail : this.myEmail,
                creationDate: new Date().toISOString().substring(0,10)
            }
        );
        if(this.libraryEntryId)
        {
            try
            {
                this.dataFetching = true;

                this.entry = await this.libraryApiClient.getOne(this.libraryEntryId);
                this.entry.description = decode(this.entry?.description);
                this.global = !this.entry.organizationId && !this.entry.userId;
                this.organizational = this.entry.organizationId && !this.entry.userId;
                this.existingUserId = this.entry.userId;
                this.entry.protectedInformation ||= new ProtectedInformation();

                //Assigning an id to each hint so we can distinguish one from another (when editing it)
                let i = 0;
                this.entry.hints = this.entry.hints.map(h => {h['id'] = h['id'] || `${Config.TEMP_HINT_ID_PREFIX}${i++}`; return h});
            }
            catch
            {
                this.close();
            }
            finally
            {
                this.dataFetching = false;
            }
        }
        else {
            this.entry.settings.maxNumberOfAttempts = Config.DEFAULT_MAX_NUMBER_OF_ATTEMPTS;
            this.entry.settings.isDefaultChallenge = false;
            this.entry.enabled = true;
            this.entry.category = Config.UNCATEGORIZED;
        }

        this.organizations = await listOrganizationsPromise;
        
        //Set a default organization for organizational visibility picker
        if(this.organizations.length > 0)
        {
            this.organizationId = this.organizational
                ? this.entry.organizationId
                : this.organizations[0].id
        }
    }

    async confirm()
    {
        await new Promise(res => setTimeout(res, 1)); // This allows for changes from focused input fields to settle before making the network request
        if((<any>this.$refs.form).validate() === false) return;

        //Extract new file artifacts
        let newFileArtifacts = this.entry.artifacts.filter(a => !!a['file']);
        
        let desiredEntry = new LibraryEntry({
                name: this.entry.name,
                description: encode(this.entry.description),
                category: this.entry.category || Config.UNCATEGORIZED,
                points: Number(this.entry.points),
                artifacts: this.entry.artifacts.filter(a => !a['file']),
                hints: this.entry.hints.map(h => Hint.fromJson(h)),
                choice: this.entry.choice,
                flags: this.entry.flags,
                settings: this.entry.settings,
                tags: this.entry.tags,
                enabled: this.entry.enabled,
                notes: this.entry.notes,
                solution: this.entry.solution
            })

        if (useAuthorizationStore().canUpdateChallengeProtectedInformation()) {
            desiredEntry.protectedInformation = this.entry.protectedInformation;
        }

        if(this.isEditing)
        {
            //Set Global/Organizational/Personal visibility for those who can manage all library entries
            if(this.canCreateGlobalEntry)
            {
                if(this.global)
                {
                    desiredEntry.organizationId = "";
                    desiredEntry.userId = "";
                }
                else if(this.organizational)
                {
                    desiredEntry.organizationId = this.organizationId;
                    desiredEntry.userId = "";
                }
                else
                {
                    desiredEntry.organizationId = "";
                    // prevent superadmin from "stealing" others user's library items on edit.
                    desiredEntry.userId = this.existingUserId || this.myUserId; 
                }
            }
            await this.libraryApiClient.update(this.libraryEntryId, desiredEntry);
        }
        else
        {   
            //Set Global/Organizational/Personal visibility
            if(this.global && this.canCreateGlobalEntry)
            {
                desiredEntry.organizationId = undefined;
                desiredEntry.userId = undefined;
            }
            else if(this.organizational && this.canCreateOrganizationalEntry)
            {
                desiredEntry.organizationId = this.organizationId;
                desiredEntry.userId = undefined;
            }
            else
            {
                desiredEntry.organizationId = undefined;
                desiredEntry.userId = this.myUserId;
            }
            
            desiredEntry.id = await this.libraryApiClient.create(desiredEntry);
        }
        
        //Upload new artifact files
        try
        {
            this.isUploading = true;
            await this.uploadArtifactFiles(this.libraryEntryId || desiredEntry.id, newFileArtifacts);
        }
        finally
        {
            this.percentCompleted = 0;
            this.isUploading = false;
        }

        this.$emit('confirm', desiredEntry);
        this.close();
    }

    async uploadArtifactFiles(libraryEntryId:string, artifacts:IArtifact[]): Promise<void>
    {
        //Upload the smallest files first
        artifacts = artifacts.sort((a,b) => a['file'].size < b['file'].size ? -1 : 1);

        //Get the total upload size
        let totalSize = artifacts.map(artifact => artifact['file'].size).reduce((a,b) => a + b, 0);

        let uploadedSize = 0;

        //Prepare api request configurations
        let config = new ApiRequestConfig({},
                undefined,
                customApiErrorHandler,
                apiCallingHandler,
                apiCalledHandler
        );
        config['onUploadProgress'] =  (progressEvent) =>
        {
            this.percentCompleted = Math.min(Math.round(((uploadedSize + progressEvent.loaded) * 100) / totalSize), 99);
        }

        //Upload each artifact file
        for(let artifact of artifacts)
        {
            let file = new File({
                name: artifact.name,
                type: artifact['file'].type
            });

            try
            {
                await this.libraryApiClient.upload(libraryEntryId, file, artifact['blob'], artifact['file'].name, config);
                uploadedSize += artifact['file'].size; 
            }
            finally
            {
                //Work around a bug in ApiClient that a per-request config overrides a global config
                config.headers['Content-Type'] = "application/json";
            }
        }
    }

    async cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>
<style>
.v-subheader
{
    height: unset;
}
</style>