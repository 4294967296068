<template>
    <v-toolbar class="pr-5 pl-5 header elevation-0">

        <template v-if="competition && competition.name">
            <router-link to="/" :aria-label="$t('HEADER_HOME_LOGO_LINK_LABEL')">
                <img v-if="competition.logo" :src="competition.logo" class="logo" id="competitionLogo" alt="">
                <img v-else-if="organizationLogo" :src="organizationLogo" class="logo" id="organizationLogo" alt="">
                <img v-else :src="defaultLogo" class="logo" id="defaultLogo" alt="">
            </router-link>

            <v-toolbar-items class="pl-5" v-if="fetching">
                <loading class="mt-5" />
            </v-toolbar-items>

            <ul class="v-toolbar__items pl-5" v-else>
                <li v-if="isLogin && notWelcomePage && !isJoiningATeam && canAccessCurrentCompetition()" class="header-item">
                    <v-btn id="headerChallenge" text :to="{name:'challenges'}" :dark='isDark'>{{$t('HEADER_CHALLENGES')}}</v-btn>
                </li>
                <li v-if="isLogin && notWelcomePage && myTeamId && canAccessCurrentCompetition()" class="header-item">
                    <v-btn id="headerTeam" text :to="{name:teamRouteName}" :dark='isDark'>{{$t('HEADER_TEAM')}}</v-btn>
                </li>
                <li v-if="showScoreboard" class="header-item">
                    <v-btn id="headerScoreboard" text :to="{name:scoreboardRouteName}" :dark='isDark'>{{$t('HEADER_SCOREBOARD')}}</v-btn>
                </li>
                <li v-if="isLogin && notWelcomePage && canUpdateCompetition(this.competition.id, this.competition.organizationId)" class="header-item">
                    <v-btn id="headerManage" text :to="{name:'manage'}" :dark='isDark'>{{$t('HEADER_MANAGE')}}</v-btn>
                </li>
            </ul>
        </template>

        <v-spacer></v-spacer>

        <avatar-menu v-if="isLogin" />

    </v-toolbar>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Route from '@/interfaces/route';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IPlayer, PlayerStatus } from '@cyber-range/cyber-range-api-ctf-player-client';
import defaultDarkLogo from '@/assets/logo/cloud_ctf_dark.svg';
import defaultLightLogo from '@/assets/logo/cloud_ctf_light.svg';
import { useThemeStore } from "@/stores/themeStore";
import { storeToRefs } from "pinia";
import { useOrganizationStore } from '@stores/organizationStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { usePlayerStore } from "@/stores/playerStore";
import { useTeamStore } from "@/stores/teamStore";

@Component({
    components: { }
})
export default class Header extends Vue {
    messages:string[] = [];
    fetching:boolean = false;

    get myTeamId(): string
    {
        return useTeamStore().getMyTeam
    }
    get myPlayer(): IPlayer
    {
        return usePlayerStore().getCurrentPlayer;
    }

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLogin(): boolean
    {
        return useAuthenticationStore().isLogin;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    organizationLogo = storeToRefs(useOrganizationStore()).currentOrganizationLogo;

    canUpdateCompetition(competitionId:string, organizationId: string):boolean
    {
        return useAuthorizationStore().canUpdateCompetition(competitionId, organizationId);
    }
    canAccessCurrentCompetition():boolean
    {
        return useAuthorizationStore().canAccessCurrentCompetition();
    }
    // END TODO

    get defaultLogo()
    {
        return this.isDark ? defaultDarkLogo : defaultLightLogo;
    }

    get notWelcomePage()
    {
        return this.$route.name !== Route.Welcome.name;
    }

    get isJoiningATeam()
    {
        return this.myPlayer?.status === PlayerStatus.Joining;
    }
    
    get teamRouteName()
    {
        return Route.Team.name;
    }

    get scoreboardRouteName()
    {
        return Route.Scoreboard.name;
    }
    
    get isScoreboardEnabled()
    {
        return this.competition?.settings?.hideDashboard !== true;
    }

    get showScoreboard(): boolean
    {
        if (this.isScoreboardEnabled)
        {
            return this.isScoreboardPublic || (this.isLogin && this.notWelcomePage && this.canAccessCurrentCompetition())
        }
        else
        {
            return this.isLogin && this.notWelcomePage && useAuthorizationStore().canViewHiddenScoreboard(this.competition.id, this.competition.organizationId);
        }
    }

    get isScoreboardPublic()
    {
        return this.competition?.settings?.allowUnregisteredUsersToViewDashboard === true;
    }

    async created()
    {
        this.fetching = true;

        try
        {
            if(this.myPlayer === undefined)
            {
                await usePlayerStore().fetchCurrentPlayer();
            }
        }
        finally
        {
            this.fetching = false;
        }
    }
}
</script>

<style scoped>
@import './header.css';
</style>