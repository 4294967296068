<template>
    <confirmation-dialog v-model="showDialog" :title="title" :confirm="$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CONFIRM')" @confirm="confirm" :cancel="false" :loading="isLoading" class="submissionResultDialog">
        <p v-html="message" />
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IChallenge, ISubmissionResult } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { Getter } from 'vuex-class';
import StoreGetter from '@/interfaces/storeGetter';
import { encode } from 'html-entities';

@Component({components:{}})
export default class SubmissionResultDialog extends Vue 
{  
    @Prop(Object) challenge:IChallenge;
    @Prop(Object) submissionResult:ISubmissionResult;
    @Prop(Boolean) value:boolean;
    @Getter(StoreGetter.GetChallenge) getChallenge: (id: string) => IChallenge;
    @Getter(StoreGetter.IsLocked) isLocked: (id: string, ignoreChallengeId: string) => boolean;
    
    showDialog = false;

    get title()
    {
        return this.submissionResult.correct ? this.$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_TITLE') : this.$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_INCORRECT_TITLE')
    }

    get unlocks(): string[]
    {
        const challenges: string[] = []
        for (const id of this.challenge.unlocks || [])
        {
            const challenge = this.getChallenge(id);
            if (!challenge?.name) continue;

            const isAlreadyUnlocked = !this.isLocked(id, this.challenge.id);
            if(!isAlreadyUnlocked)
            {
                challenges.push(challenge.name);
            }
        }
        return challenges;
    }

    get message()
    {
        let message = ""
        if (this.submissionResult.correct && this.unlocks.length)
        {
            message = this.$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_WITH_UNLOCKS_MESSAGE', {
                points: this.submissionResult.points,
                challenges: new Intl.ListFormat(undefined, { type: 'conjunction' }).format(this.unlocks.map(name => `<b>${encode(name)}</b>`))
            }).toString();
        }
        else if (this.submissionResult.correct)
        {
            message = this.$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_MESSAGE', {points: this.submissionResult.points}).toString();
        }
        else
        {
            message = this.$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_INCORRECT_MESSAGE').toString();
        }
        return message
    }

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    
    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(value) this.showDialog = value;
    }

    async mounted() 
    {
        this.showDialog = this.value; 
    }
    
    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>