<template>
    <div>
        <v-form v-if="showForm" :lazy-validation="true" ref="form" @submit.prevent="()=>{}" class="joinTeamForm">
            <h3 class="mb-5" >{{$t('WELCOME_JOIN_A_TEAM_TITLE')}}</h3>
            <v-row>
                <v-col cols="12">
                    {{$t('WELCOME_JOIN_A_TEAM_MESSAGE')}}
                </v-col>
                <v-col cols="12">
                    <v-autocomplete v-model="teamId" :items="teams" item-text="name" item-value="id" 
                    :label="$t('WELCOME_JOIN_A_TEAM_TEAM_NAME')"  :no-data-text="$t('WELCOME_JOIN_A_TEAM_TEAM_NO_MATCHED')"
                    :rules="teamRules" :disabled="isLoading" :dark="isDark" class="joinTeamList" id="joinTeamList"/>
                </v-col>
                <v-col cols="12">
                    <v-btn @click="onCanceled" :disabled="isLoading" :dark="isDark" text class="joinTeamCancel">
                        {{$t('WELCOME_JOIN_A_TEAM_CANCEL')}}
                    </v-btn>
                    <v-btn @click="onConfirmed" :disabled="isLoading" :loading="showForm && isLoading" :dark="!isConfirmLight" :light="isConfirmLight" color="confirm" class="elevation-0 joinTeamConfirm">
                        {{$t('WELCOME_JOIN_A_TEAM_CONFIRM')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-btn v-else style='width:100%' text @click="onClicked" :disabled="isLoading" :dark="isDark" id="joinTeamButton">
            {{$t('WELCOME_JOIN_A_TEAM')}}
        </v-btn>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { ITeamApiClient, ITeam, TeamFilter } from '@cyber-range/cyber-range-api-ctf-team-client';
import { IPlayerApiClient, Player } from '@cyber-range/cyber-range-api-ctf-player-client';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import Route from '@/interfaces/route';
import Config from '@/config';
import { IPageResponse } from '@cyber-range/cyber-range-api';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useEntitlementStore } from '@stores/entitlementStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { usePlayerStore } from '@/stores/playerStore';

@Component
export default class PlayingSolo extends Vue 
{   
    @Prop(Boolean) value;
    @Prop(String) intitalTeamId;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isConfirmLight(): boolean
    {
        return useThemeStore().isConfirmLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get teamApiClient(): ITeamApiClient
    {
        return useApiClientStore().teamApiClient;
    }
    get identityId(): string
    {
        return useAuthenticationStore().identityId;
    }
    get identityName(): string
    {
        return useAuthenticationStore().identityName;
    }
    get identityEmail(): string
    {
        return useAuthenticationStore().identityEmail;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO
    
    teamId:string = '';
    showForm:boolean = false;
    teams:ITeam[] = [];
    teamRules = [Rule.selectRequire];

    @Watch("value")
    async onValueChanged()
    { 
        if(this.value) await this.load();
    }

    async mounted() 
    {
        this.showForm = this.value;
        this.teamId = this.intitalTeamId; //for testing purposes

        if(this.value) await this.load();
    }

    async onClicked()
    {
        this.$emit('input', true);
        this.showForm = true;
    }

    async onCanceled()
    {
        this.$emit('input', false);
        this.showForm = false;
    }

    async onConfirmed()
    {
        await this.submit();
    }

    teamIsFull(teamListItem:Partial<ITeam>):boolean
    {
        return !!this.competition?.limits && ((teamListItem?.statistics?.numberOfPlayers || 0) >= this.competition?.limits?.maxPlayersPerTeam);
    }

    async load()
    {
        let teams:ITeam[] = [];

        let filter = new TeamFilter({competitionId: this.competition.id, limit: Config.DEFAULT_FETCH_SIZE});
        let page:IPageResponse<ITeam>;

        do
        {
            page = await this.teamApiClient.get(filter);
            filter.token = page.nextPageToken;
            teams = teams.concat(page.items);
        }
        while(page.nextPageToken);
        for(let team of teams)
        {            
            if(this.teamIsFull(team))
            {
                team['disabled'] = true;
                team.name = (this.$t('WELCOME_JOIN_A_TEAM_IS_FULL',{teamName: team.name}).toString()) as string;
            }
        }

        this.teams = teams;
    }

    async submit()
    {
        if((<any>this.$refs.form).validate() === false) return;

        let player = new Player({
                competitionId: this.competition.id,
                userId: this.identityId,
                teamId: this.teamId,
                name: this.identityName,
                email: this.identityEmail
            })

        await usePlayerStore().createPlayer(player);

        this.$router.replace(Route.Challenges);
    }
}
</script>