enum StoreMutation
{
    //AuthenticationStore
    SetToken = "setToken",
    SetOriginalToken = "setOriginalToken",
    SetTokenTimer = "setTokenTimer",
    ClearTokenTimer = "clearTokenTimer",
    ResetIdentityProvider = 'resetIdentityProvider',
    //ChallengeStore
    SetChallenges = "setChallenges",
    ClearChallenges = "clearChallenges",
    //SubmissionStore
    ClearSubmissions = "clearSubmissions",
    BeginSubmissionFetching = "beginSubmissionFetching",
    EndSubmissionFetching = "endSubmissionFetching",
    AddSubmission = "addSubmission",
    Solved = "solved",
    ResetSolved = "resetSolved",
    SetLatestCorrectionTimestamp = 'setLatestCorrectionTimestamp',
    SetMyTeamsChallengeStatistics = "SetMyTeamsChallengeStatistics",
    //AlertStore
    AddAlert = "addAlert",
    RemoveAlert = "removeAlert",
    ResetAlerts = "resetAlerts",
};

export default StoreMutation;