<template>
    <v-row wrap>
        <v-col cols="12" class="pt-0 pb-0 mb-0 mt-0 addFlag">
            <sorted-combobox v-model="newTag" :items="allKnownTags" :rules="tagRules" item-text="name" item-value="name" :label="$t('TAGS_ADD')" :disabled="isLoading" :hide-no-data="true" :return-object="false" @change="onTagChanged" :dark="isDark" ref="newTag" class="newTag" id="newTag"/>
        </v-col>
        <v-col cols="12" class="pa-0 mb-0 mt-1">
            <v-chip v-for="(tag,index) in tags" :key="index" class="flag ma-1" color="tag" close :aria-label="tag" @click:close="onDeleteClicked(index)" @keyup.enter="onDeleteClicked(index)" @keyup.space="onDeleteClicked(index)">{{trim(tag)}}</v-chip>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { IKnowledge } from '@cyber-range/cyber-range-api-content-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useKnowledgeStore } from '@stores/knowledgeStore';

@Component
export default class Tags extends Vue 
{  
    @Prop(Array) tags:string[];
    
    @Getter(StoreGetter.GetChallenges) challenges: ()=>IChallenge[];

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get knowledges(): IKnowledge[]
    {
        return useKnowledgeStore().getKnowledges
    }
    // END TODO

    allKnownTags:string[] = [];
    challengeTags:string[] = [];
    newTag:string = '';
    searchInput:string = '';
    maxTagLength:number = 256;

    get tagRules()
    {
        return [(v)=>Rule.maxLength(v, this.maxTagLength)]
    }

    trim(s:string):string
    {
        return s?.length > 30 ? s.substring(0, 30) + '...' : s;
    }

    onTagChanged(value)
    {
        if(!value) return;

        if(!(<any>this.$refs.newTag?.['$children']?.[0]?.validate()))
        {
            this.$emit('error', true);
            return;
        }

        this.$emit('error', false);
        this.tags.push(value);            
        if(!this.allKnownTags.includes(value))
        { 
            this.allKnownTags.push(value); 
        }

        //Clear the combobox
        this.$nextTick(() => {
            this.newTag = '';
        });

        //Inactivate the combobox's menu
        this.$refs.newTag['isMenuActive'] = false;
    }

    async mounted()  
    {
        if(this.knowledges.length === 0)
        {
            await useKnowledgeStore().fetchKnowledges();
        }

        if(this.challengeTags.length === 0)
        {
            this.challengeTags = this.challenges().map(c => c.tags).reduce((allTags, tags)=>allTags.concat(tags), []);
        }

        this.allKnownTags = [...new Set([...this.knowledges.map(k => k.name), ...this.challengeTags])]
    }

    onDeleteClicked(index:number)
    {
        this.tags.splice(index, 1);
    }
}
</script>