<template>
    <div class="addChallengeMenu">
        <v-menu offset-y :dark="isDark" :light="isLight">
            <template v-slot:activator="{ on: menu, value }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn outlined small fab v-on="{ ...tooltip, ...menu }" class="addChallengeButton" aria-owns="addChallengeMenuList" :aria-label="$t('CHALLENGE_ADD_MENU_LABEL')" aria-haspopup="true" :aria-expanded="`${value}`">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('CHALLENGE_ADD_TOOLTIP')}}</span>
                </v-tooltip>
            </template>
            <v-list :dark="isDark" :light="isLight" id="addChallengeMenuList">
                <v-list-item>
                    <v-list-item-title>
                        <v-btn text @click="onAddChallengeFromLibraryClicked" class="addChallengeFromLibraryButton">{{$t('CHALLENGE_ADD_FROM_LIBRARY')}}</v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn text @click="onAddChallengeFromScratchClicked" class="addChallengeFromScrathButton">{{$t('CHALLENGE_ADD_FROM_SCRATCH')}}</v-btn>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <add-challenges-from-library-dialog v-if="entries.length > 1" v-model="showAddChallengeDialog" @confirm="onChallengesAdded" @cancel="onCancelChallengesAddded" :libraryEntries="entries" />
        <add-edit-challenge-dialog v-else-if="entries.length === 1" v-model="showAddChallengeDialog" @confirm="onChallengesAdded([$event])" @cancel="onCancel" id="addEditChallengeFromScratchDialog" :libraryEntryId="entries[0].id" />
        <library-entries-dialog v-model="showAddChallengeFromLibraryDialog" :entries.sync="entries" @confirm="onLibraryEntriesSelected" @cancel="onCancel" id="addEditChallengeFromLibraryDialog" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ILibraryEntry, LibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import { useThemeStore } from '@/stores/themeStore';

@Component
export default class AddChallengeMenu extends Vue 
{
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isLight;
    }
    // END TODO

    entries:ILibraryEntry[] = [];
    showAddChallengeDialog:boolean = false;
    showAddChallengeFromLibraryDialog:boolean = false;

    onAddChallengeFromScratchClicked()
    {
        this.entries = [new LibraryEntry()];
        this.showAddChallengeDialog = true;
    }

    onAddChallengeFromLibraryClicked()
    {
        this.showAddChallengeFromLibraryDialog = true;
    }

    onChallengesAdded(challenges:IChallenge[])
    {
        this.$emit('added', challenges);
        this.entries = []
    }

    onCancelChallengesAddded()
    {
        this.showAddChallengeDialog = false;
        this.showAddChallengeFromLibraryDialog = true;
    }

    onCancel()
    {
        this.entries = [];
    }
    
    onLibraryEntriesSelected()
    {
        this.showAddChallengeDialog = true;
    }
}
</script>
