<template>
    <confirmation-dialog v-model="showDialog" :title="challenge?.name" :loading="isLoading" :cancel="false" :confirm="$t('OK')" @confirm="onConfirm">
        <v-col cols="12" class="pt-0 pb-0">
            <p v-html="unlockByHtml" />
        </v-col>
    </confirmation-dialog>
</template>

<script setup lang="ts">
import StoreGetter from '@/interfaces/storeGetter';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { computed, getCurrentInstance, shallowRef, watch } from 'vue';
import { useApiClientStore } from '../../../../stores/apiClientStore';
import { encode } from 'html-entities';

const vm = getCurrentInstance();

const props = defineProps<{
    challengeId: string
    value: boolean
}>();

const emits = defineEmits<{
    (name: 'input', value: boolean): void
}>()

const showDialog = computed({
    get: () => props.value,
    set:(value: boolean) => emits('input', value)
});
const onConfirm = () => showDialog.value = false;

const apiClientStore = useApiClientStore()
const isLoading = computed(() => apiClientStore.isLoading);

const challenge = shallowRef<IChallenge>();
watch(() => props.value, async () => 
{
    if (props.value) {
        challenge.value = await apiClientStore.challengeApiClient.getOne(props.challengeId);
    }
}, { immediate: true });

const unlockByHtml = computed(() =>
{
    if (!challenge.value) return "";

    const challenges = []
    const getChallenge: (id:string) => IChallenge|undefined = vm.proxy.$store.getters[StoreGetter.GetChallenge];
    for (const id of challenge.value.unlockedBy || [])
    {
        const name = getChallenge(id)?.name;
        if (!name) continue;
        challenges.push(`<b>${encode(name)}</b>`);
    }
    return vm.proxy.$t('CHALLENGE_LOCKED_DIALOG_TEXT', {
        challenges: new Intl.ListFormat(undefined, { type: 'disjunction' }).format(challenges)
    }).toString();
});
</script>
