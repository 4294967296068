<template>
    <div>
        <loading v-if="isForegroundFetching" class="teamSubmissionsTableLoading"/>
        <loading v-else-if="isBackgroundFetching" class="teamSubmissionsTableUpdating" :message="$t('TEAM_UPDATING')" />

        <v-row v-else-if="submissions.length === 0">
            <v-col cols="12" align="center" class="mt-10 pt-10">
                <p class="teamSubmissionsTableEmpty">{{$t('TEAMS_SUBMISSIONS_TABLE_EMPTY')}}</p>
            </v-col>
        </v-row>

        <v-simple-table v-else :fixed-header="true" :dark="isDark" class="teamSubmissionsTable mb-2 alphaBackground">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-if="canUnsolveSubmission"><td><span class="visually-hidden">{{$t('TEAMS_SUBMISSIONS_TABLE_ACTIONS')}}</span></td></th>
                        <th class="text-left teamSubmissionsTableTimeColumn">{{$t('TEAMS_SUBMISSIONS_TABLE_TIME')}}</th>
                        <th class="text-left teamSubmissionsTableChallengeColumn">{{$t('TEAMS_SUBMISSIONS_TABLE_CHALLENGE')}}</th>
                        <th class="text-left teamSubmissionsTableSolverColumn">{{$t('TEAMS_SUBMISSIONS_TABLE_SOLVER')}}</th>
                        <th class="text-right teamSubmissionsTablePointsEarnedColumn pr-5">{{$t('TEAMS_SUBMISSIONS_TABLE_POINTS_EARNED')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="submission in submissions" :key="submission.id">
                        <td v-if="canUnsolveSubmission">
                            <accessible-menu offset-y :label="$t('TEAMS_SUBMISSIONS_ACTIONS_LABEL', { name: submission.challengeName })">
                                <v-list>
                                    <v-list-item @click="onUnsolveClicked(submission)">
                                        <v-list-item-avatar>
                                            <v-icon>delete</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-title>{{$t('TEAMS_UNSOLVE')}}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </accessible-menu>
                        </td>
                        <td class="text-left teamSubmissionsTableTimeColumn">{{ submission.createdTimestamp | humanizedShort}}</td>
                        <td class="text-left teamSubmissionsTableChallengeColumn">
                            {{ submission.challengeName }}
                            <span v-if="isHint(submission)">
                                {{$t('TEAMS_SUBMISSIONS_TABLE_HINT')}}
                            </span>
                            <span v-else-if="isCorrection(submission)">
                                {{$t('TEAMS_SUBMISSIONS_TABLE_CORRECTION')}}
                            </span>
                        </td>
                        <td class="text-left teamSubmissionsTableSolverColumn">{{ (players[submission.playerId] || {}).name }}</td>
                        <td class="text-right teamSubmissionsTablePointsEarnedColumn pr-5">{{ submission.scoreAdjustment }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <unsolve-submission-dialog v-if="canUnsolveSubmission" v-model="showUnsolveSubmissionDialog" :submission="selectedSubmission" @confirm="load"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { ISubmission, SubmissionType, Submission } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { Prop, Watch } from 'vue-property-decorator';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IPlayerApiClient, IPlayer, PlayerFilter } from '@cyber-range/cyber-range-api-ctf-player-client';
import Config from '@/config';
import StoreAction from '@/interfaces/storeAction';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { usePlayerStore } from '@/stores/playerStore';
import { useTeamStore } from '@/stores/teamStore';

@Component
export default class TeamSubmissionsTable extends Vue 
{   
    @Prop(String) teamId:string;
    @Prop(Boolean) isDark:boolean;

    get myTeamId(): string
    {
        return useTeamStore().getMyTeam
    }
    get myTeamPlayers(): IPlayer[]
    {
        return usePlayerStore().getMyTeamPlayers;
    }
    get isMyTeamPlayersFetched(): boolean
    {
        return usePlayerStore().isMyTeamPlayersFetched;
    }
    @Getter(StoreGetter.IsSubmissionFetched) isSubmissionFetched:boolean;
    @Getter(StoreGetter.GetTeamSubmissions) getTeamSubmissions: (teamId:string)=>ISubmission[];

    get canUnsolveSubmission(): boolean
    {
        return useAuthorizationStore().canUnsolveSubmission(this.competition.id, this.competition.organizationId);
    }

    @Action(StoreAction.FetchSubmissions) fetchSubmissions: (options?:{background:boolean})=>Promise<IPlayer[]>;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO
    
    players:{[playerId:string]:IPlayer} = {};
    isForegroundFetching = false;
    isBackgroundFetching = false;

    selectedSubmission:ISubmission = new Submission();
    showUnsolveSubmissionDialog:boolean = false;

    get submissions()
    {
        return this.getTeamSubmissions(this.teamId);
    }

    @Watch("teamId")
    async onTeamIdChanged()
    {
        await this.load();
    }

    async mounted()
    {
        await this.load();
    }

    async load() 
    {
        let foregroundPromises = [];
        let backgroundPromises = [];

        //Fetch submissions
        if(!this.isSubmissionFetched)
        {
            foregroundPromises.push(this.fetchSubmissions());
        }
        else
        {
            backgroundPromises.push(this.fetchSubmissions({background:true}));
        }

        //Fetch team players
        let rawPlayers:IPlayer[] = [];

        if(this.teamId === this.myTeamId)
        {
            if(!this.isMyTeamPlayersFetched)
            {
                foregroundPromises.push(new Promise<void>((resolve, reject)=>{
                    usePlayerStore().fetchMyTeamPlayers().then((players:IPlayer[])=>{
                        rawPlayers=players;
                        resolve();
                    }).catch(reject);
                }));
            }
            else
            {
                rawPlayers = this.myTeamPlayers;
            }
        }
        else
        {
            rawPlayers = await this.fetchTeamPlayers(this.teamId);
        }

        //Execute forground promises
        if(foregroundPromises.length > 0)
        {
            this.isForegroundFetching = true;
            try
            {
                await Promise.all(foregroundPromises);
            }
            finally
            {
                this.isForegroundFetching = false;
            }
        }

        //All data should be fetched and ready to render.
        rawPlayers.forEach(p => this.players[p.id] = p);

        //Continue to fetch more data in the background. This is to get the most up-to-date scores.
        if(backgroundPromises.length > 0)
        {
            this.isBackgroundFetching = true;
            try
            {
                await Promise.all(backgroundPromises);

                rawPlayers.forEach(p => this.players[p.id] = p);
            }
            finally
            {
                this.isBackgroundFetching = false;
            }
        }
    }

    async fetchTeamPlayers(teamId:string):Promise<IPlayer[]>
    {
        let result:IPlayer[] = [];

        let filter = new PlayerFilter({competitionId: this.competition.id, teamId: this.teamId, limit: Config.DEFAULT_FETCH_SIZE});

        do 
        {
            let page = await this.playerApiClient.get(filter);
            filter.token = page.nextPageToken;
            result = result.concat(page.items);
        } 
        while (filter.token);

        return result;
    }

    isHint(submission:ISubmission): boolean
    {
        return submission.type === SubmissionType.Hint;
    }

    isCorrection(submission:ISubmission): boolean
    {
        return submission.type === SubmissionType.Correction
    }

    onUnsolveClicked(submission:ISubmission)
    {
        this.selectedSubmission = submission;
        this.showUnsolveSubmissionDialog = true;
    }
}
</script>

<style>
.teamSubmissionsTable .v-data-table__wrapper{
    max-height: 320px;
}
.teamSubmissionsTable.theme--light tbody td{
    color: var(--v-dialogText-lighten2) !important;
}
.teamSubmissionsTable.theme--dark thead th,
.teamSubmissionsTable.theme--dark {
    
}  
</style>