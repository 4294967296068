<template>
    <v-btn style='width:100%' text @click="onClicked" :disabled="isLoading" :loading="clicked && isLoading" :dark="isDark" id="playSoloButton">
        {{$t('WELCOME_PLAY_SOLO')}}
    </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IPlayerApiClient, Player } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import Route from '@/interfaces/route';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useEntitlementStore } from '@stores/entitlementStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { usePlayerStore } from '@/stores/playerStore';

@Component
export default class PlayingSoloOption extends Vue 
{
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get identityId(): string
    {
        return useAuthenticationStore().identityId;
    }
    get identityName(): string
    {
        return useAuthenticationStore().identityName;
    }
    get identityEmail(): string
    {
        return useAuthenticationStore().identityEmail;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    clicked:boolean = false;

    async onClicked()
    {
        let player = new Player({
                competitionId: this.competition.id,
                userId: this.identityId,
                name: this.identityName,
                email: this.identityEmail
         })
        
        try
        {
            this.clicked = true;

            await usePlayerStore().createPlayer(player);
            
            this.$router.replace(Route.Challenges);
        }
        finally
        {
            this.clicked = false;
        }
    }
}
</script>