import { defineStore } from 'pinia'
import NotificationEvent from '@/interfaces/NotificationEvent';
import { IPlayerApiClient, PlayerFilter, IPlayer, IPlayerPage } from '@cyber-range/cyber-range-api-ctf-player-client';
import Config from '@/config';
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useTeamStore } from './teamStore';
import { useEntitlementStore } from './entitlementStore';

export const usePlayerStore = defineStore('playerStore', 
{
    state: () =>
    ({
        player: undefined as IPlayer|undefined,
        myTeamPlayers: [] as IPlayer[],
        isMyTeamPlayersFetched: false
    }),
    getters: 
    {
        getCurrentPlayer(state): IPlayer
        {
            return state.player || undefined;
        },
        getMyTeamPlayers(state): IPlayer[]
        {
            return state.myTeamPlayers;
        },
    },
    actions: 
    {
        setCurrentPlayer(player:IPlayer): void
        {
            this.player = player;
        },
        setMyTeamPlayers(myTeamPlayers:IPlayer[] = []): void
        {
            this.myTeamPlayers = myTeamPlayers;
            this.isMyTeamPlayersFetched = myTeamPlayers.length !== 0;
        },
        async fetchPlayers(options?:{background:boolean}): Promise<IPlayer[]>
        {
            let client:IPlayerApiClient = options?.background ? useApiClientStore().backgroundPlayerApiClient : useApiClientStore().playerApiClient;

            let players = [];
    
    
            let filter = new PlayerFilter({competitionId: useCompetitionStore().currentCompetition.id, limit:Config.DEFAULT_FETCH_SIZE});
            let page:IPlayerPage;
            
            do
            {
                page = await client.get(filter);
                filter.token = page.nextPageToken;
                players = players.concat(page.items);
            }
            while(page.nextPageToken);
    
            return players;
        },

        async fetchCurrentPlayer(options?:{background:boolean}): Promise<IPlayer> 
        {
            //FetchCurrentPlayer is called from <Header> which has to support both
            //logged-in-users and unauthenticated-users.  If the user is not logged in,
            //simply return undefined.
            if(useAuthenticationStore().isLogin === false)
            {
                this.setCurrentPlayer();
                return undefined;
            }

            let client:IPlayerApiClient = options?.background ? useApiClientStore().backgroundPlayerApiClient : useApiClientStore().playerApiClient;
            
            let filter = new PlayerFilter({competitionId: useCompetitionStore().currentCompetition.id, 
                                           userId: useAuthenticationStore().identityId
                                           });
            
            let page = await client.get(filter);

            let player = page.items.length > 0 ? page.items[0] : undefined;
            
            this.setCurrentPlayer(player);

            if(!useNotificationStore().isSubscribed(NotificationEvent.PlayerUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.PlayerUpdated, 
                    callback: ()=>this.fetchCurrentPlayer({ background: true })
                }); 
            };

            return player;
        },
        async fetchMyTeamPlayers(options?:{background:boolean}): Promise<IPlayer[]> 
        {
            let players:IPlayer[] = [];

            let competition = useCompetitionStore().currentCompetition;
            let myTeamId = useTeamStore().getMyTeam || await useTeamStore().fetchMyTeam();

            let client:IPlayerApiClient = options?.background ? useApiClientStore().backgroundPlayerApiClient : useApiClientStore().playerApiClient;
            
            let filter = new PlayerFilter({competitionId: competition.id, teamId: myTeamId, limit: Config.DEFAULT_FETCH_SIZE});
            let page:IPlayerPage;
    
            do
            {
                page = await client.get(filter);
                filter.token = page.nextPageToken;
                players = players.concat(page.items);
            }
            while(filter.token);
    
            this.setMyTeamPlayers(players);

            if(!useNotificationStore().isSubscribed(NotificationEvent.TeamMembersUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.TeamMembersUpdated, 
                    callback: ()=>this.fetchMyTeamPlayers({background: true})
                }); 
            };

            return players;
        },
        async startSession()
        {
            const player = this.getCurrentPlayer || await this.fetchCurrentPlayer();
            if (!player) return;

            const competitionId = useCompetitionStore().currentCompetition?.id
            if (!competitionId)
            {
                return
            }

            await useApiClientStore().backgroundPlayerApiClient.startSession(player.id, { competitionId });
        },
        async renewSession()
        {
            const player = this.getCurrentPlayer || await this.fetchCurrentPlayer();
            if (!player) return;

            await useApiClientStore().backgroundPlayerApiClient.renewSession(player.id);
        },
        async terminateSession()
        {
            const player = this.getCurrentPlayer || await this.fetchCurrentPlayer();
            if (!player) return;

            await useApiClientStore().backgroundPlayerApiClient.terminateSession(player.id, undefined, { errorHandler:()=>{}});
        },
        async createPlayer(player: IPlayer)
        {
            await useApiClientStore().playerApiClient.create(player);
            await useEntitlementStore().fetchClaims();
            await this.fetchCurrentPlayer(); //to get the new joining status
            await this.startSession();
        }
    }
});
