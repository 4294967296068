<template>
    <div>
        <v-select :dark="false" :light="true" v-model="selectedFamilyId" :items="catalogFamilyItems" :label="$t('ARTIFACTS_ADD_EDIT_DIALOG_ENVIRONMENT_VALUE')" :disabled="isLoading" :rules="[Rule.require]" />
        <v-select v-for="parameter in selectableRecipeParameters" :key="parameter.id" :label="parameter.name" item-value="value" item-text="name" :items="parameter.options" v-model="selectedParameters[parameter.id]" required :disabled="isLoading"/>
    </div>
</template>

<script setup lang="ts">
import { useAuthenticationStore } from "@/stores/authenticationStore";
import { useSubscriptionStore } from "@/stores/subscriptionStore";
import { FamilyRecipeParameter, ICatalogFamily, ICatalogFamilyCatalogEntryView, IFamilyRecipeParameter, IRecipeParameterOption } from "@cyber-range/cyber-range-api-catalog-client";
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue";
import { useCatalogStore } from "../../../stores/catalogStore";
import { useCompetitionStore } from "../../../stores/competitionStore";
import Rule from '../../../validations/Rule';
import { useCatalogSelection } from "./useCatalogSelection";

const subscriptionStore = useSubscriptionStore();

const props = defineProps<{
    value?: string
}>();

const emit = defineEmits<{
    (name: 'input', value: string): void
}>();

const isLoading = ref(false);

const catalogFamilies = ref<ICatalogFamily[]>([]);
type selectItem = { text: string, value: string };
const catalogFamilyItems = computed<Array<selectItem|{ header: string }>>(() =>
{
    const copiedEnvironments: selectItem[] = [];
    const environments: selectItem[] = [];
    const partnerEnvironments: Record<string, selectItem[]> = {};

    const competition = useCompetitionStore().currentCompetition;
    const currentUserId = useAuthenticationStore().identityId

    for (const family of catalogFamilies.value || [])
    {
        const { organizationId, userId, name, id, metadata, catalogEntries } = family;

        if ((organizationId && organizationId !== competition.organizationId)
            || (userId && userId !== currentUserId))
        {
            continue;
        }
        const hasNecessarySubscription = catalogEntries?.some(entry => !entry.dependsOnProducts?.length || entry.dependsOnProducts.every(productId => subscriptionStore.hasSubscribedProduct(productId)));
        if (!hasNecessarySubscription)
        {
            continue;
        }

        const data = { text: name, value: id };
        if (metadata?.tags?.includes('environmentTypes:snapshot'))
        {
            copiedEnvironments.push(data);
        }
        else if (metadata?.tags?.find(tag => tag.startsWith('coursewarePartners:')))
        {
            const partner = metadata.tags.find(tag => tag.startsWith('coursewarePartners:')).slice(19);
            const key = `${partner} Environments`
            partnerEnvironments[key] ??= [];
            partnerEnvironments[key].push(data);
        }
        else
        {
            environments.push(data);
        }
    }

    const keyToHeader = (key: string) => key.replace('_', ' ').replace(/(?<!\w)(\w)/g, (_, c) => c.toUpperCase());
    const selectItemSort = (a: selectItem, b: selectItem) => a.text.localeCompare(b.text, undefined, { numeric: true, sensitivity: 'base' });

    const familyCategories = { 'Copied Environments': copiedEnvironments, ...partnerEnvironments, Environments: environments };
    return Object.entries(familyCategories)
        .filter(([_, families]) => families.length > 0)
        .map(([key, families]) => key ? [{ header: keyToHeader(key) }, families.sort(selectItemSort)] : families.sort(selectItemSort))
        .flat(2);
});
const selectedFamilyId = ref<string>();
const catalogFamily = ref<ICatalogFamily>();

watch(selectedFamilyId, async (id) => 
{
    if (!id || catalogFamily.value?.id === id) return;
    catalogFamily.value = await useCatalogStore().getCatalogFamily(id);
})

const { recipeParameters, selectedParameters } = useCatalogSelection(
    computed(() => catalogFamily.value?.recipeParameters),
    computed(() => catalogFamilies.value.find(family => family.id === catalogFamily.value.id)?.catalogEntries)
);

// Only show select component for parameters with options
const selectableRecipeParameters = computed(() => recipeParameters.value.filter(parameter => parameter.options));

// When displayed recipe parameters change, update selectedParameters accordingly
watch(() => recipeParameters, () =>
{
    // A temporary object is used so selectedParameters doesn't change until all recipe parameters have been iterated over, and so that no longer used keys aren't kept around
    const tempSelectedParameters: Record<IFamilyRecipeParameter['id'],IRecipeParameterOption['value']> = {};

    for (const parameter of recipeParameters.value)
    {
        // Use current selected value if it exists in the currently displayed parameter's options. This won't be the case where parameters with the same id but different options swap visibility due making a different selection for a prior parameter
        let selectedValue = parameter.options?.find(option => option.value === selectedParameters.value[parameter.id])?.value;
        // Otherwise select the default value
        selectedValue ||= parameter.default;
        tempSelectedParameters[parameter.id] = selectedValue;
    }

    // Don't set selectedParameters if nothing has changed
    if (JSON.stringify(selectedParameters.value) !== JSON.stringify(tempSelectedParameters))
    {
        selectedParameters.value = tempSelectedParameters;
    }
}, { immediate: true, deep: true });

watch(() => selectedParameters.value['catalogid'], (id) =>
{
    if (props.value !== id)
    {
        emit('input', id);
    }
});

onMounted(async () =>
{
    isLoading.value = true;

    catalogFamilies.value = await useCatalogStore().listCatalogFamilies();

    if (props.value)
    {
        const familyId = catalogFamilies.value.find(({ catalogEntries }) => catalogEntries.some(({ id }) => id === props.value))?.id;
        if (familyId)
        {
            catalogFamily.value = await useCatalogStore().getCatalogFamily(familyId);
            selectedFamilyId.value = familyId;
            const rp = catalogFamily.value.recipeParameters.find(rp => rp.id === 'catalogid' && rp.default === props.value);
            selectedParameters.value = { ...rp?.when, 'catalogid': props.value };
        }
    }

    isLoading.value = false;
})
</script>
